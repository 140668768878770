<div class="flex h-full min-h-full w-full">
  <!-- Sidebar for conversations -->
  <div class="flex flex-column w-12 surface-section surface-border border-1 border-round" [ngClass]=" { 'lg:w-3' :
    selectedConversation?.messages, 'lg:w-12' : !selectedConversation?.messages }">
    <h2 class="text-xl font-medium mb-3 p-1 text-center">Conversations</h2>
    <ul class="list-none p-0 m-0 overflow-y-auto">
      <li *ngIf="conversations.length === 0" class="text-center ">
        <p class="text-color-secondary p-3">

          No conversations available.
        </p>
        <p-button label="Start a new conversation" icon="pi pi-plus" styleClass="p-button-outlined"
          (click)="startConversation()">
        </p-button>
      </li>
      <li *ngFor="let conversation of conversations"
        class="flex align-items-center p-3  surface-border cursor-pointer hover:surface-200 border-round"
        [ngClass]="{ 'bg-blue-100': conversation === selectedConversation }" (click)="selectConversation(conversation)">
        <p-avatar [label]="conversation.name[0]" shape="circle" class="mr-3" size="large"></p-avatar>
        <div>
          <span class="font-bold">{{ conversation.name }}</span>
          <p class="text-sm text-color-secondary m-0">
            {{ conversation.lastMessage }}
          </p>
        </div>
      </li>
    </ul>
  </div>

  <!-- Main message content area -->
  <div class="flex-1 flex flex-column w-12 lg:w-9 border-1 surface-border border-round m-2" *ngIf="
    selectedConversation?.messages">
    <!-- Header -->
    <div class="flex align-items-center justify-content-between p-1 surface-section border-bottom-1 surface-border">
      <p-button icon="pi pi-bars" (click)="conversationVisible = true" styleClass="p-button-text sm:hidden"></p-button>
      <h1 class="text-xl font-bold m-0">Messages</h1>
      <p-button icon="pi pi-cog" styleClass="p-button-text"></p-button>
    </div>

    <!-- Messages area -->
    <p-scrollPanel [style]="{ width: '100%', height: '100%', outline: 'solid red 1px' }"
      class="flex-grow-1 overflow-y-auto">
      <div class="p-3">
        <div *ngIf="selectedConversation?.messages?.length === 0" class="text-center text-color-secondary text-2xl">
          No messages to display.
        </div>
        <!-- message bubble -->
        <div *ngFor="let message of selectedConversation?.messages" class="flex mb-4"
          [ngClass]="{ 'justify-content-end text-right': message.sender === 'You' }">
          <div class="p-3 border-round-lg shadow-2" [ngClass]=" { 'bg-blue-500 text-white' : message.sender !=='You'
            ,'bg-gray-300 text-800': message.sender==='You' }">
            <p class="font-bold m-0 mb-2 text-sm">
              {{ message.sender }}
            </p>
            <p class="m-0 mb-2 text-lg" [ngClass]="{
              'text-white': message.sender !== 'You','text-800': message.sender === 'You'}">{{ message.content }}
            </p>
            <span class="mt-1  text-sm text-left" [ngClass]="{
              'text-white': message.sender !== 'You','text-color-secondary': message.sender === 'You'}">{{
              message.timestamp
              }}</span>
            <span *ngIf="message.sender === 'You'" class="mt-1 text-color-secondary text-right">
              {{ message.status === 'delivered' ? 'Delivered' : 'Sending...' }}
            </span>
          </div>
        </div>
      </div>

    </p-scrollPanel>

    <!-- Message input -->
    <div class="p-3 flex align-items-center surface-section border-top-1 surface-border">
      <input type="text" pInputText placeholder="Type a message" class="flex-1 mr-3" [(ngModel)]="newMessage"
        (keyup.enter)="sendMessage()" />
      <p-button icon="pi pi-send" styleClass="p-button-rounded p-button-info" (click)="sendMessage()"></p-button>
    </div>
  </div>
</div>
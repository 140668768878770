import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { UsersService } from 'src/app/_share/_services/users.service';

@Component({
  selector: 'app-tenant-users',
  templateUrl: './tenant-users.component.html',
  styleUrls: ['./tenant-users.component.scss'],
})
export class TenantUsersComponent implements OnInit {
  tenantUsers: any[] = [];
  tenantUsers$: Subject<any[]> = new Subject<any[]>();

  columns = [
    { field: 'first_name', header: 'First Name' },
    { field: 'last_name', header: 'Last Name' },
    { field: 'email', header: 'Email' },
    { field: 'username', header: 'Username' },
    { field: 'role.name', header: 'Role' },
    { field: 'status', header: 'Status' },
  ];

  constructor(private usersService: UsersService) {}

  ngOnInit(): void {
    this.usersService.getAll().subscribe((data: any) => {
      console.log('users', data);
      this.tenantUsers = data;
      this.tenantUsers$.next(this.tenantUsers);
    });
  }

  getNumberofColumns() {
    return this.columns.length;
  }
}

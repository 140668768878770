import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PortalProducts } from 'src/app/_share/_models/portal/interfaces';
import { BillingService } from '../../../main-application/_services/billing.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit {
  tabs = [
    { key: 'overview', name: 'Overview' },

    { key: 'products-and-promotions', name: 'Products and Promotions' },
    { key: 'billing', name: 'Billing' },
    { key: 'customers', name: 'Customers' },
    { key: 'orders', name: 'Orders' },
    { key: 'management', name: 'Services Management' },
  ];

  constructor(private bs: BillingService) {}

  ngOnInit(): void {}
}

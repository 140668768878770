<div class="flex">
  <div class="flex-1">
    <!-- <p-selectButton
      [options]="stateOptions"
      [(ngModel)]="userType"
      optionLabel="label"
      optionValue="value"
    ></p-selectButton> -->
    <h1 class="text-3xl font-semibold text-blue-400">
      Register and Purchase WiFi at
      <span class="font-bold text-green-500">{{
        config.data.dialogDetails.park.name
      }}</span>
    </h1>

    <p class="text-lg text-gray-600">
      Please fill in the form below to register as a new user at
      <span class="font-bold">{{ config.data.dialogDetails.park.name }}</span>
    </p>
  </div>
</div>
<form [formGroup]="portalUserRegisterForm" (ngSubmit)="onRegisterSubmit()">
  <div class="formgrid grid p-fluid">
    <div class="field col-12 md:col-2">
      <label for="title">Title<span class="text-red-600">*</span></label>
      <p-dropdown
        id="title"
        [options]="titles"
        formControlName="title"
        filterBy="label"
        optionLabel="label"
        optionValue="value"
        placeholder="Select Title"
        (onBlur)="portalUserRegisterForm.controls['title'].markAsDirty()"
      >
      </p-dropdown>
    </div>
    <div class="field col-12 md:col-5">
      <label for="first_name"
        >First Name<span class="text-red-600">*</span></label
      >
      <input
        pInputText
        id="first_name"
        formControlName="first_name"
        type="text"
        class="w-full p-inputtext-lg"
        (blur)="portalUserRegisterForm.controls['first_name'].markAsDirty()"
      />
      <small
        *ngIf="
          portalUserRegisterForm.controls['first_name'].hasError('required') &&
          portalUserRegisterForm.controls['first_name'].touched &&
          portalUserRegisterForm.controls['first_name'].invalid
        "
        id="email-help"
        class="p-error"
        >This field is required</small
      >
    </div>
    <div class="field col-12 md:col-5">
      <label for="last_name"
        >Last Name<span class="text-red-600">*</span></label
      >
      <input
        pInputText
        id="last_name"
        formControlName="last_name"
        type="text"
        class="w-full p-inputtext-lg"
        (blur)="portalUserRegisterForm.controls['last_name'].markAsDirty()"
      />
      <small
        *ngIf="
          portalUserRegisterForm.controls['last_name'].hasError('required') &&
          portalUserRegisterForm.controls['last_name'].touched &&
          portalUserRegisterForm.controls['last_name'].invalid
        "
        id="last_name-help"
        class="p-error"
        >This field is required</small
      >
    </div>
    <div class="field col-12 md:col-6">
      <label for="email">Email<span class="text-red-600">*</span></label>
      <input
        pInputText
        id="email"
        formControlName="email"
        type="email"
        class="w-full"
        pKeyFilter="email"
        aria-describedby="email-help"
        (blur)="portalUserRegisterForm.controls['email'].markAsDirty()"
      />
      <small
        *ngIf="
          portalUserRegisterForm.controls['email'].hasError('required') &&
          portalUserRegisterForm.controls['email'].touched &&
          portalUserRegisterForm.controls['email'].invalid
        "
        id="email-help"
        class="p-error"
        >This field is required</small
      >
      <small
        *ngIf="
          portalUserRegisterForm.controls['email'].hasError('email') &&
          portalUserRegisterForm.controls['email'].touched &&
          portalUserRegisterForm.controls['email'].invalid
        "
        id="email-help"
        class="p-error"
        >Please enter a valid email</small
      >
    </div>
    <div class="field col-12 md:col-6">
      <label for="mobile_number"
        >Mobile Number<span class="text-red-600">*</span></label
      >
      <input
        pInputText
        id="mobile_number"
        formControlName="mobile_number"
        type="text"
        class="w-full"
        aria-describedby="mobile_number-help"
        [pValidateOnly]="true"
        [pKeyFilter]="ukPhoneNumberRegex"
        [pattern]="ukPhoneNumberRegex"
        (blur)="portalUserRegisterForm.controls['mobile_number'].markAsDirty()"
      />
      <small id="mobile_number-help" class="block"
        >You can start with a 0 or +44 when entering your mobile number</small
      >
      <small
        *ngIf="
          portalUserRegisterForm.controls['mobile_number'].hasError(
            'required'
          ) &&
          portalUserRegisterForm.controls['mobile_number'].touched &&
          portalUserRegisterForm.controls['mobile_number'].invalid
        "
        id="mobile_number-help"
        class="p-error"
        >This field is required</small
      >
      <small
        *ngIf="
          portalUserRegisterForm.controls['mobile_number'].hasError(
            'pattern'
          ) &&
          portalUserRegisterForm.controls['mobile_number'].touched &&
          portalUserRegisterForm.controls['mobile_number'].invalid
        "
        id="mobile_number-help"
        class="p-error"
        >Please enter a valid UK mobile number</small
      >
    </div>
    <div class="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
    <div class="field col-12">
      <p class="text-lg text-gray-600">
        Please select a password you will remember. This password is used to
        login to the hotspot service after purchase.
      </p>
    </div>
    <div class="field col-12 md:col-6">
      <label for="password">Password<span class="text-red-600">*</span></label>
      <p-password
        formControlName="password"
        [toggleMask]="true"
        (blur)="portalUserRegisterForm.controls['password'].markAsDirty()"
        id="password"
        class="w-full"
        mediumRegex="^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})."
      >
        <!-- <ng-template pTemplate="header">
          <h5>Pick a password</h5>
        </ng-template>
        <ng-template pTemplate="footer">
          <p-divider></p-divider>
          <p class="mt-2">Suggestions</p>
          <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
            <li>At least one lowercase</li>
            <li>At least one uppercase</li>
            <li>At least one numeric</li>
            <li>Minimum 6 characters</li>
          </ul>
        </ng-template> -->
      </p-password>
      <small
        *ngIf="
          portalUserRegisterForm.controls['password'].hasError('minlength') &&
          portalUserRegisterForm.controls['password'].touched &&
          portalUserRegisterForm.controls['password'].invalid
        "
        id="password"
        class="p-error m-1"
        >The field <span class="font-bold">Password</span> must be at least 6
        characters</small
      >
      <small
        *ngIf="
          portalUserRegisterForm.controls['password'].hasError('maxlength') &&
          portalUserRegisterForm.controls['password'].touched &&
          portalUserRegisterForm.controls['password'].invalid
        "
        [id]="'password'"
        class="p-error m-1"
        >The field <span class="font-bold">Password</span> must be at most 20
        characters</small
      >
    </div>
    <div class="field col-12 md:col-6">
      <label for="confirm_password"
        >Confirm Password<span class="text-red-600">*</span></label
      >
      <input
        pInputText
        id="confirm_password"
        formControlName="confirm_password"
        type="password"
        class="w-full"
        (blur)="portalUserRegisterForm.controls['mobile_number'].markAsDirty()"
      />
    </div>

    <div class="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
    <div class="field col-12">
      <p-message
        *ngIf="portalUserRegisterForm.errors?.['PasswordNoMatch']"
        severity="error"
        class="w-full"
        text="The entered passwords do not match"
      ></p-message>

      <p-message
        *ngIf="
          portalUserRegisterForm.controls['confirm_password'].value ===
            portalUserRegisterForm.controls['password'].value &&
          !portalUserRegisterForm.errors?.['PasswordNoMatch'] &&
          portalUserRegisterForm.controls['confirm_password'].value != '' &&
          portalUserRegisterForm.controls['password'].value != ''
        "
        severity="success"
        class="w-full"
        text="The passwords match!"
      ></p-message>
    </div>

    <div class="field col-12 md:col-4">
      <button
        *ngIf="
          (registrationSuccess$ | async) === false &&
          !portalUserRegisterForm.invalid
        "
        pButton
        type="submit"
        class="p-button-success p-button p-button-lg"
        label="Submit Registration"
      ></button>
    </div>
    <div class="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
  </div>
</form>
<form [formGroup]="paymentForm">
  <div
    *ngIf="(registrationSuccess$ | async) === true"
    class="formgrid grid p-fluid"
  >
    <div class="field col-12">
      <p-dropdown
        #planDropdown
        placeholder="Select a Wi-Fi Plan"
        [options]="packages"
        optionLabel="name"
        optionValue="value"
        formControlName="plan"
        [(ngModel)]="selectedPackage"
        [tabindex]="0"
      >
      </p-dropdown>
    </div>

    <div
      class="col-12 md:col-9 field align-items-center justify-content-center"
    >
      <p *ngIf="selectedPackage">
        You have selected
        <span class="text-lg font-bold">{{
          getPackageDetails(selectedPackage).name
        }}</span>
        which costs
        <span class="text-lg font-bold text-green-500">{{
          getPackageDetails(selectedPackage).price | currency: "GBP"
        }}</span>
      </p>
    </div>

    <div class="col-12 field">
      <!-- <ngx-stripe-card-group
        [formGroup]="paymentForm"
        [elementsOptions]="elementsOptions"
        [stripe]="stripe"
      >
        <ngx-stripe-card-number
          [options]="cardOptions"
        ></ngx-stripe-card-number>

        <ngx-stripe-card-expiry
          [options]="cardOptions"
        ></ngx-stripe-card-expiry>

        <ngx-stripe-card-cvc [options]="cardOptions"></ngx-stripe-card-cvc>
      </ngx-stripe-card-group> -->
      <ngx-stripe-card
        (change)="onCardChange($event)"
        [options]="cardOptions"
        [elementsOptions]="elementsOptions"
        [stripe]="stripe"
      ></ngx-stripe-card>
    </div>
    <div class="surface-border border-top-1 opacity-50 mb-4 col-12"></div>

    <div class="field col-12 md:col-4">
      <button
        pButton
        type="button"
        class="p-button-success p-button p-button-lg"
        label="Pay"
        (click)="payOld()"
        icon="pi pi-money-bill"
        [loading]="this.paymentLoading"
      ></button>
    </div>
    <div class="field col-12">
      <p-message
        #errormsg
        *ngIf="errorMessage != ''"
        severity="error"
        [text]="errorMessage"
        class="w-full"
        hideTransitionOptions="500ms ease-out"
      ></p-message>
    </div>
  </div>
</form>

import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { NetworkCustomersService } from '../../_services/network-customers.service';
import { NetworkInvoicesService } from '../../_services/network-invoices.service';

@Component({
  selector: 'app-services-customers',
  templateUrl: './services-customers.component.html',
  styleUrls: ['./services-customers.component.scss'],
})
export class ServicesCustomersComponent implements OnInit {
  selectedCustomer: any;

  customers: any[] = [];
  customers$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    this.customers,
  );

  customerInvoices: any[] = [];
  customerInvoices$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    this.customerInvoices,
  );

  customerInvoicesLoading: boolean = false;

  invoiceColumns: any[] = [
    { field: 'invoice_number', header: 'Invoice Number', type: 'text' },
    { field: 'invoice_date', header: 'Invoice Date', type: 'date' },
    { field: 'due_date', header: 'Due Date', type: 'date' },
    {
      field: 'total_amount_excl',
      header: 'Total Amount (Excl)',
      type: 'currency',
    },
    {
      field: 'total_amount_incl',
      header: 'Total Amount (Incl)',
      type: 'currency',
    },
    { field: 'total_discount', header: 'Total Discount', type: 'number' },
    { field: 'currency', header: 'Currency', type: 'text' },
  ];

  constructor(
    private nc: NetworkCustomersService,
    private nis: NetworkInvoicesService,
  ) {}
  ngOnInit(): void {
    this.nc.getCustomers().subscribe((customers) => {
      this.customers = customers;
      this.customers$.next(customers);
      console.log(customers);
    });

    if (this.selectedCustomer) {
      this.getCustomerInvoices();
    }
  }

  onCustomerChange($event: any) {
    console.log($event);
    this.getCustomerInvoices();
  }

  getCustomerInvoices() {
    this.customerInvoicesLoading = true;
    this.nis
      .getInvoicesByCustomerId(this.selectedCustomer.id)
      .subscribe((invoices) => {
        this.customerInvoices = invoices;
        this.customerInvoices$.next(invoices);
        console.log(invoices);
        this.customerInvoicesLoading = false;
      });
  }

  getNumberOfColumns() {
    return this.invoiceColumns.length;
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { timer } from 'rxjs';
import { PaymentGatewayService } from 'src/app/features/portal/_services/payment-gateway.service';

import { PortalTransactionsService } from 'src/app/features/portal/_services/portal-transactions.service';
import { PortalUsersService } from 'src/app/features/portal/_services/portal-users.service';
import { PortalTransactions } from 'src/app/_share/_models/portal/interfaces';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-portal-redirect',
  templateUrl: './portal-redirect.component.html',
  styleUrls: ['./portal-redirect.component.scss'],
})
export class PortalRedirectComponent implements OnInit {
  waitingForRedirect: boolean = false;
  payment_id: number = 0;

  PAYFAST_SANDBOX = false;

  INTENTIO_SANDBOX_MERCHANT_ID = '10030227';
  INTENTIO_SANDBOX_MERCHANT_KEY = 'vz10qlkkuj0zn';

  GPS_LIVE_MERCHANT_ID = '16375350';
  GPS_LIVE_MERCHANT_KEY = 'hqakby2pfdauv';

  PAYFAST_PAYMENT_METHODS = {
    creditcard: { value: '', label: 'Credit Card' },
    cash: { value: 'mu', label: 'Mukuru Cash' },
  };

  returnUrl: string = 'http://ftthsa.co.za/';
  cancelUrl: string = 'http://ftthsa.co.za/';

  payfastPaymentForm = this.fb.group({
    // merchant_id: ['10030227'],
    // merchant_key: ['vz10qlkkuj0zn'],
    merchant_id: [''],
    merchant_key: [''],
    amount: ['0.00'],
    // initial_amount: ['0.00'],
    // passphrase: ['HelloThisIsATest'],
    // recurring_amount: [''],
    // subsciption_type: ['1'],
    // frequency: [''],
    // cycles: [''],
    item_name: ['Test'],
    return_url: [
      // `${environment.appUrl}/#/portal/payment/confirmation?status=success`,
      '',
    ],
    cancel_url: [
      // `${environment.appUrl}/#/portal/payment/confirmation?status=cancel`,
      '',
    ],
    notify_url: [
      // `https://stm-dev.intentio.co.za/api/portal/notify`
      `${environment.apiUrl}/portal/notify`,
    ],
    subscription_type: ['2'], // tokenization
    // fica_idnumber
    // name_first
    // name_last
    email_address: [''],
    cell_number: [''],
    m_payment_id: [''],
    // item_name
    item_description: [''],
    payment_method: [''],
    passphrase: ['Gl0balPl4tinumS0lutions'],
    custom_str1: [''],
  });
  tokenisation: boolean = false;
  paymentOverride: string = '';

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private pgService: PaymentGatewayService,
    // private paymentsService: PortalPaymentsService,
    private transactionsService: PortalTransactionsService,
    private pucService: PortalUsersService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      console.log('Params: ', params);
    });
    this.route.queryParams.subscribe((params) => {
      console.log('Query Params: ', params);

      if (params['gateway'] == 'paygate') {
        this.waitingForRedirect = true;
        timer(2000).subscribe(() => {
          this.pendingRedirect();
        });
      } else if (params['gateway'] == 'payfast') {
        if (params['payment_id']) {
          this.payment_id = +params['payment_id'];
        }
        if (params['token'] == '1') {
          console.log('Tokenisation is enabled');
          this.tokenisation = true;
        } else {
          console.log('Tokenisation is disabled');
          this.tokenisation = false;
        }
        this.waitingForRedirect = true;
        // this.generatePayfastPaymentRequest();
        this.generatePayfastPaymentRequest();
      }
    });
  }

  getFormControls(formGroup: FormGroup) {
    return Object.keys(formGroup && formGroup.controls);
  }

  pendingRedirect() {
    // todo: portal - redirect - pending redirect

    this.waitingForRedirect = false;
    window.location.href = 'https://google.com';
  }

  generatePaygatePaymentRequest() {
    this.pgService
      .paygateInitPayment(
        1000,
        'dittmarmax@gmail.com',
        // todo: this needs to be a unique reference
        'Prepaid FTTH - '
      )
      .subscribe(
        (response: any) => {
          console.log('Response: ', response);
          this.pgService.payment_request_id = response['pay_request_id'];
          this.pgService.checksum = response['checksum'];
          this.pgService.waitingForRedirect = true;
          if (this.pgService.waitingForRedirect) {
            window.setTimeout(() => {
              console.log(
                'The timer is over',
                (
                  document.getElementById(
                    'paygateSubmissionForm'
                  ) as HTMLFormElement
                ).submit()
              );
            }, 5000);
          }
        },
        (error) => {
          console.log('Error: ', error);
        }
      );
  }

  generatePayfastPaymentRequest() {
    this.transactionsService
      .getPortalTransactionsById(this.payment_id)
      .subscribe((res: PortalTransactions) => {
        console.log('res payments', res);

        const payment_object = res;

        if (
          payment_object?.portal_end_customer?.email != null &&
          payment_object?.portal_end_customer?.email != ''
        ) {
          this.payfastPaymentForm.patchValue({
            email_address: payment_object?.portal_end_customer?.email,
            cell_number:
              payment_object?.portal_end_customer?.mobile_number?.replace(
                /^27/,
                '0'
              ),
          });
        } else {
          this.payfastPaymentForm.patchValue({
            cell_number:
              payment_object?.portal_end_customer?.mobile_number?.replace(
                /^27/,
                '0'
              ),
          });
        }

        let paymentMethod = 'cc';

        if (payment_object.transaction_method) {
          if (payment_object.transaction_method == 'creditcard') {
            paymentMethod = 'cc';
          } else if (payment_object.transaction_method == 'cash') {
            paymentMethod = 'mu';
            (this.payfastPaymentForm as FormGroup<any>).removeControl(
              'subscription_type'
            );
          } else if (payment_object.transaction_method == 'eft') {
            paymentMethod = 'eft';
            (this.payfastPaymentForm as FormGroup<any>).removeControl(
              'subscription_type'
            );
          }
        }

        if (this.tokenisation === false) {
          (this.payfastPaymentForm as FormGroup<any>).removeControl(
            'subscription_type'
          );
        }

        if (
          payment_object.transaction_description
            ?.toLowerCase()
            .includes('activation')
        ) {
          this.returnUrl = 'https://ftthsa.co.za/SuccessPayment';
          this.cancelUrl = 'https://ftthsa.co.za/FailedPayment';
        } else {
          this.returnUrl = 'https://ftthsa.co.za/MonthlySuccessPayment';
          this.cancelUrl = 'https://ftthsa.co.za/MonthlyFeeFailedPayment';
        }

        if (payment_object.transaction_method == 'cash') {
          this.returnUrl = 'https://ftthsa.co.za/CashPendingPayment';
        }

        // if (this.PAYFAST_SANDBOX) {
        //   this.returnUrl = `${environment.appUrl}/#/portal/payment/confirmation?status=success`;
        //   this.cancelUrl = `${environment.appUrl}/#/portal/payment/confirmation?status=cancel`;
        // }

        this.payfastPaymentForm.patchValue({
          return_url: this.returnUrl,
          cancel_url: this.cancelUrl,
        });

        if (this.PAYFAST_SANDBOX) {
          this.payfastPaymentForm.patchValue({
            merchant_id: this.INTENTIO_SANDBOX_MERCHANT_ID,
            merchant_key: this.INTENTIO_SANDBOX_MERCHANT_KEY,
          });
        } else {
          this.payfastPaymentForm.patchValue({
            merchant_id: this.GPS_LIVE_MERCHANT_ID,
            merchant_key: this.GPS_LIVE_MERCHANT_KEY,
          });
        }

        // todo: portal - improve mapping to payfast form

        // console.log('payment object', payment_object);

        this.payfastPaymentForm.patchValue({
          amount: payment_object.amount_incl.toString(),
          item_name:
            payment_object.reference +
            ' - ' +
            payment_object.transaction_description,
          item_description:
            payment_object.portal_subscription?.subscription_account_reference +
            ' - ' +
            payment_object.reference +
            ' - ' +
            payment_object.transaction_description,
          m_payment_id: payment_object.reference,
          payment_method: paymentMethod,
          custom_str1:
            payment_object.portal_subscription?.subscription_account_reference,
        });
        window.setTimeout(() => {
          console.log(
            'The timer is over',
            (document.getElementById('payfastForm') as HTMLFormElement).submit()
          );
        }, 2500);
      });

    // fixme: putback, just removed for testing
  }

  generateStripePaymentRequest() {}

  formatDate(date: Date) {
    // dd-mm-yyyy

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Zero-pad the month
    const day = date.getDate().toString().padStart(2, '0'); // Zero-pad the day

    const formattedDate = year + '-' + month + '-' + day;

    return formattedDate;
  }

  convertE163ToLocal(e163Number: string, countryCode: string) {
    // Remove leading plus sign if present
    e163Number = e163Number.replace(/^\+/, '');

    // Extract country code from E.163 number
    const e163CountryCode = e163Number.substring(0, countryCode.length);

    // Check if the extracted country code matches the provided country code
    if (e163CountryCode !== countryCode) {
      // If not, return the original number
      return e163Number;
    }

    // Extract the national significant number
    const nationalNumber = e163Number.substring(countryCode.length);

    // Format the local number as needed (you may need to adjust this based on the local convention)
    const localNumber = nationalNumber;
    // Add any formatting logic here, such as adding dashes or parentheses

    return localNumber;
  }
}

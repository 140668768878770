import { Component, OnInit } from '@angular/core';
import { NetworkServicesService } from '../../_services/network-services.service';
import { BehaviorSubject } from 'rxjs';
import { NetworkServices } from '../../_models/network-services.model';

@Component({
  selector: 'app-services-management',
  templateUrl: './services-management.component.html',
  styleUrls: ['./services-management.component.scss'],
})
export class ServicesManagementComponent implements OnInit {
  services: any[] = [];
  services$: BehaviorSubject<NetworkServices[]> = new BehaviorSubject<
    NetworkServices[]
  >([]);
  columns: any[] = [];
  selectedRow: any;

  private initializeColumns(): void {
    const model: NetworkServices = {
      service_status: '',
      // Add other fields from the NetworkServices model here
      date_activated: new Date(),
      change_logs: [],
    };

    this.columns = Object.keys(model).map((key) => ({
      name: this.formatColumnName(key),
      field: key,
    }));
  }

  private formatColumnName(key: string): string {
    return key
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  constructor(private nss: NetworkServicesService) {}

  ngOnInit(): void {
    this.nss.getNetworkServices().subscribe((res: NetworkServices[]) => {
      this.services = res;
      this.services$.next(res);
      console.log('nmmp services', res);
    });

    this.initializeColumns();
  }

  getNumberOfColumns(): number {
    return this.columns.length;
  }

  onRowSelect($event: any) {
    throw new Error('Method not implemented.');
  }

  // getNestedValue(obj: any, path: string) {

  //   returnObject.value = path
  //     .split('.')
  //     .reduce((acc, part) => acc && acc[part], obj);

  //   returnObject.nested = path.includes('.');

  //   returnObject.type = typeof returnObject.value;

  //   if (Array.isArray(returnObject.value)) {
  //     returnObject.plural = true;
  //     returnObject.nested = true;
  //     returnObject.items = returnObject.value;
  //     returnObject.pluralField = path.split('.')[1];
  //     returnObject.type = 'array';
  //   } else if (
  //     typeof returnObject.value === 'object' &&
  //     returnObject.value !== null
  //   ) {
  //     returnObject.plural = false;
  //     returnObject.items = [returnObject.value];
  //     returnObject.type = 'object';
  //   } else if (typeof returnObject.value === 'string') {
  //     // fixme: remove automatic formatting of string to dates
  //     // const date = new Date(returnObject.value);
  //     // if (!isNaN(date.getTime())) {
  //     //   returnObject.value = date;
  //     //   returnObject.type = 'date';
  //     // }
  //   }

  //   return returnObject;
  // }

  resolveFieldData(object: any, field: any) {
    // resolve fields with dot notation
    if (field.indexOf('.') === -1) {
      return object[field];
    }
  }
}

<div class="grid">
  <div class="col-8">
    <div class="surface-card shadow-2 p-4">
      <div class="flex align-items-center justify-content-between mb-4">
        <div class="text-900 font-medium text-xl">Customer Circuits</div>
        <!-- <div><button pButton icon="pi pi-refresh"></button></div> -->
      </div>
      <p-dropdown
        [options]="areas"
        [(ngModel)]="this.selectedArea"
        optionLabel="name"
        placeholder="Select Area"
        (onChange)="onAreaChange($event, map)"
      ></p-dropdown>
      <google-map
        #map
        [options]="mapOptions"
        height="70vh"
        width="100%"
        [zoom]="12"
      >
        <map-marker
          *ngFor="let overlay of overlays"
          [position]="overlay.position"
          [title]="overlay.title"
          [label]="overlay.label"
          [icon]="overlay.icon"
          (click)="onMarkerClick(overlay)"
        ></map-marker>
        <map-kml-layer
          *ngFor="let kml of kmls"
          [url]="kml.url"
          [options]="kml.options"
        ></map-kml-layer>
      </google-map>
    </div>
  </div>
  <div class="col-4">
    <app-network-alarms></app-network-alarms>
  </div>
</div>

<!-- statistics summary -->
<div class="grid grid-nogutter col-12">
  <ng-container [ngSwitch]="summaryTopic">
    <ng-container *ngSwitchCase="'device'">
      <div class="col-6 md:col-1">
        <div class="surface-card shadow-1 border-gray-900 font-bold">
          <div class="text-center p-2">
            <p>Services Configured:</p>
            <span class="text-4xl font-bold">{{ summaryData.configured }}</span>
          </div>
        </div>
      </div>
      <div class="col-6 md:col-1">
        <div class="surface-card shadow-1 border-gray-900 font-bold">
          <div class="text-center p-2">
            <p>Unprovisioned:</p>
            <span class="text-4xl font-bold">{{ summaryData.available }}</span>
          </div>
        </div>
      </div>
      <div class="col-6 md:col-1">
        <div class="surface-card shadow-1 border-gray-900 font-bold">
          <div class="text-center p-2">
            <p>Billed:</p>
            <span class="text-4xl font-bold">{{ summaryData.billed }}</span>
          </div>
        </div>
      </div>
      <div class="col-6 md:col-1">
        <div class="surface-card shadow-1 border-gray-900 font-bold">
          <div class="text-center p-2">
            <p>Notified:</p>
            <span class="text-4xl font-bold">{{ summaryData.notified }}</span>
          </div>
        </div>
      </div>
      <div class="col-6 md:col-2">
        <div class="shadow-1 border-gray-900 font-bold bg-primary-reverse">
          <div class="text-center p-2">
            <p>Devices Authorised:</p>
            <span class="text-4xl font-bold">{{ summaryData.configured }}</span>
          </div>
        </div>
      </div>
      <div class="col-6 md:col-2">
        <div class="shadow-1 border-gray-900 font-bold bg-primary">
          <div class="text-center p-2">
            <p>Devices Online:</p>
            <span class="text-4xl font-bold">{{ summaryData.configured }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-4">
        <div class="shadow-1 font-bold bg-primary-reverse">
          <div class="text-center p-2">
            <p>Total Devices:</p>
            <span class="text-4xl font-bold">{{ summaryData.total }}</span>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'region'">
      <div class="col-12">
        <div class="shadow-1 font-bold">
          <!-- <p-gmap
            [options]="mapOptions"
            [style]="{ width: '100%', height: '320px' }"
          ></p-gmap> -->
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<!-- col - 3  -->
<!-- <div class="col-12 md:col-0"></div> -->
<!-- col - 6 -->
<div class="grid">
  <div class="col-12 md:col-3 md:align-items-center md:justify-content-between">
    <div class="px-2 py-4 md:px-1 lg:px-2">
      <div
        class="flex flex-column md:align-items-center md:justify-content-between md:flex-row"
      >
        <div class="font-medium text-3xl text-900 pl-2">Customers</div>
        <div class="mt-3 md:mt-0">
          <button
            pButton
            pRipple
            label="Add"
            class="p-button-outlined mr-2"
            icon="pi pi-plus"
            *ngIf="false"
          ></button>
        </div>
      </div>
      <ng-container *ngIf="customers$ | async as customers; else loading">
        <p-listbox
          (onChange)="onCustomerChange($event)"
          [options]="(customers$ | async) || []"
          [(ngModel)]="selectedCustomer"
          optionLabel="name"
        >
          <ng-template let-customer pTemplate="item">
            <div class="surface-card shadow-2 border-round p-4 mb-3 w-full">
              <div class="grid">
                <div class="col-12">
                  <h3 class="text-900">
                    {{ customer.name }}
                  </h3>
                  <p class="text-600">{{ customer.email }}</p>
                  <p class="text-600">
                    <a href="{{ customer.website }}" target="_blank">{{
                      customer.website
                    }}</a>
                  </p>
                  <p class="text-600">
                    Tax Reference:
                    {{ customer.tax_reference }}
                  </p>
                  <p class="text-600">Postal Address:</p>
                  <ul class="list-none p-0 m-0">
                    <!-- <li>{{ customer.postal_address_01 }}</li>
                <li>{{ customer.postal_address_02 }}</li>
                <li>{{ customer.postal_address_03 }}</li>
                <li>{{ customer.postal_address_04 }}</li>
                <li>{{ customer.postal_address_05 }}</li> -->
                  </ul>
                  <p class="text-600">Delivery Address:</p>
                  <ul class="list-none p-0 m-0">
                    <!-- <li>
                  {{ customer.delivery_address_01 }}
                </li>
                <li>
                  {{ customer.delivery_address_02 }}
                </li>
                <li>
                  {{ customer.delivery_address_03 }}
                </li>
                <li>
                  {{ customer.delivery_address_04 }}
                </li>
                <li>
                  {{ customer.delivery_address_05 }}
                </li> -->
                  </ul>
                  <p class="text-600">ID: {{ customer.id }}</p>
                  <p class="text-600">
                    Active:
                    {{ customer.active ? "Yes" : "No" }}
                  </p>
                </div>
              </div>
            </div>
          </ng-template>
        </p-listbox>
      </ng-container>
      <ng-template #loading>
        <div class="flex flex-column align-items-center justify-content-center">
          <p-progressSpinner
            style="width: 50px; height: 50px"
          ></p-progressSpinner>
          <p>Fetching customers...</p>
        </div>
      </ng-template>
    </div>
  </div>
  <!-- col - 4 -->
  <div class="col-12 md:col-9">
    <div
      class="flex flex-column md:align-items-center md:justify-content-between md:flex-row"
    >
      <div class="flex-auto font-medium text-3xl text-900 mb-4">Invoices</div>
    </div>
    <div class="flex-1 border-round shadow-2 p-3">
      <p-table
        #billingScheduleTable
        [value]="(customerInvoices$ | async) || []"
        sortField="representative.name"
        sortMode="single"
        [scrollable]="true"
        scrollHeight="400px"
        rowGroupMode="subheader"
        groupRowsBy="billing_type"
        [loading]="customerInvoicesLoading || false"
      >
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of invoiceColumns">{{ col.header }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="groupheader" let-item>
          <tr pRowGroupHeader>
            <td [attr.colspan]="getNumberOfColumns()" class="text-center">
              <span class="font-bold ml-2">{{ item.billing_type }}</span>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
          <tr>
            <td *ngFor="let col of invoiceColumns" class="white-space-nowrap">
              <span *ngIf="col.type === 'text'">
                {{ item[col.field] }}
              </span>
              <span *ngIf="col.type === 'currency'">
                {{ item[col.field] | currency: "NAD" : "symbol-narrow" }}
              </span>
              <span *ngIf="col.type === 'date'">
                {{ item[col.field] | date: "yyyy-MM-dd" }}
              </span>
              <span *ngIf="col.type === 'number'">
                {{ item[col.field] | number }}
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          <div
            *ngIf="selectedCustomer"
            class="flex flex-column align-items-end justify-content-between text-right"
          >
            <span class="">Total (Excl): 1000.00</span>
            <span>Total (Incl): 1150.00</span>
          </div>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="getNumberOfColumns()" class="text-center">
              <div class="text-center p-4">
                <span
                  class="pi pi-info-circle text-yellow-500"
                  style="font-size: 3rem"
                ></span>
                <p class="font-bold text-lg">No billing schedule items</p>
                <p>Please select a billing run to view the schedule.</p>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

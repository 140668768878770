import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RolesService } from 'src/app/_share/_services/roles.service';

@Component({
  selector: 'app-system-roles',
  templateUrl: './system-roles.component.html',
  styleUrls: ['./system-roles.component.scss'],
})
export class SystemRolesComponent implements OnInit, OnDestroy {
  systemRoles: any[] = [];
  systemRoles$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  systemRolesQuery$: any;

  columns: any[] = [
    { field: 'name', header: 'Name' },
    { field: 'description', header: 'Description' },
    // { field: 'date_created', header: 'Created At' },
    // { field: 'date_updated', header: 'Updated At' },
  ];

  constructor(private rs: RolesService) {}

  ngOnInit(): void {
    this.systemRolesQuery$ = this.rs.getAll().subscribe((data: any) => {
      console.log('roles', data);
      this.systemRoles = data;
      this.systemRoles$.next(this.systemRoles);
    });
  }

  getNumberofColumns() {
    return this.columns.length;
  }

  ngOnDestroy(): void {
    if (this.systemRolesQuery$) {
      this.systemRolesQuery$.unsubscribe();
    }
  }
}

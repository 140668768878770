import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { SpeedDialModule } from 'primeng/speeddial';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { StyleClassModule } from 'primeng/styleclass';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TimelineModule } from 'primeng/timeline';
import { SkeletonModule } from 'primeng/skeleton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ProgressBarModule } from 'primeng/progressbar';
import { PaginatorModule } from 'primeng/paginator';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MessageModule } from 'primeng/message';
import { TabMenuModule } from 'primeng/tabmenu';
import { KeyFilterModule } from 'primeng/keyfilter';
import { PasswordModule } from 'primeng/password';
import { CaptchaModule } from 'primeng/captcha';
import { BadgeModule } from 'primeng/badge';
import { OrderListModule } from 'primeng/orderlist';
import { SplitterModule } from 'primeng/splitter';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { AccordionModule } from 'primeng/accordion';
import { ListboxModule } from 'primeng/listbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { GMapModule } from 'primeng/gmap';
import { AvatarModule } from 'primeng/avatar';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    MenubarModule,
    SplitButtonModule,
    InputTextModule,
    SidebarModule,
    PanelMenuModule,
    CardModule,
    CheckboxModule,
    ReactiveFormsModule,
    MenuModule,
    ChartModule,
    StyleClassModule,
    TabViewModule,
    SpeedDialModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    InputTextareaModule,
    InputSwitchModule,
    SliderModule,
    ContextMenuModule,

    ChipModule,
    InputMaskModule,
    InputNumberModule,
    MultiSelectModule,
    FileUploadModule,
    CalendarModule,
    CheckboxModule,
    TagModule,
    ToastModule,
    ConfirmDialogModule,
    DialogModule,
    DividerModule,
    StepsModule,
    TimelineModule,
    SkeletonModule,
    ToggleButtonModule,
    PaginatorModule,
    AutoCompleteModule,
    MessagesModule,
    MessageModule,
    TabMenuModule,
    KeyFilterModule,
    PasswordModule,
    CaptchaModule,
    BadgeModule,
    OrderListModule,

    SplitterModule,
    ScrollPanelModule,
    AccordionModule,
    ListboxModule,
    OverlayPanelModule,
    GMapModule,
    AvatarModule,
    DropdownModule,
  ],
  exports: [
    TableModule,
    ButtonModule,
    MenubarModule,
    SplitButtonModule,
    InputTextModule,
    SidebarModule,
    PanelMenuModule,
    CardModule,
    CheckboxModule,
    ReactiveFormsModule,
    MenuModule,
    ChartModule,
    StyleClassModule,
    TabViewModule,
    SpeedDialModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    DropdownModule,
    InputTextareaModule,
    InputSwitchModule,
    SliderModule,
    ContextMenuModule,
    ChipModule,
    InputMaskModule,
    InputNumberModule,
    MultiSelectModule,
    FileUploadModule,
    CalendarModule,
    CheckboxModule,
    TagModule,
    MessagesModule,
    ToastModule,
    ConfirmDialogModule,
    DialogModule,
    DividerModule,
    StepsModule,
    RadioButtonModule,
    SelectButtonModule,
    TimelineModule,
    SkeletonModule,
    ToggleButtonModule,
    PaginatorModule,
    AutoCompleteModule,
    MessageModule,
    TabMenuModule,
    KeyFilterModule,
    PasswordModule,
    CaptchaModule,
    BadgeModule,
    OrderListModule,

    SplitterModule,
    ScrollPanelModule,
    AccordionModule,
    ListboxModule,
    OverlayPanelModule,
    GMapModule,
    AvatarModule,
  ],
})
export class PrimeModule {}

import { Component, OnInit } from '@angular/core';
import { PortalSubscriptionService } from 'src/app/features/portal/_services/portal-subscription.service';
import { PortalTransactionsService } from 'src/app/features/portal/_services/portal-transactions.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { PortalActionsService } from 'src/app/features/portal/_services/portal-actions.service';
import { PortalUsersService } from 'src/app/features/portal/_services/portal-users.service';

@Component({
  selector: 'app-subscription-details',
  templateUrl: './subscription-details.component.html',
  styleUrls: ['./subscription-details.component.scss'],
})
export class SubscriptionDetailsComponent implements OnInit {
  user: any;
  loading: boolean = true;
  isError: boolean = false;

  transactions: any[] = [];
  transactions$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  actions: any[] = [];
  actions$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  subscription: any[] = [];
  subscription$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  subscriptionColumns: any[] = [
    { field: 'device_reference', header: 'Device Reference' },
    { field: 'account_status', header: 'Account Status' },
    { field: 'subscription_status', header: 'Subscription Status' },
    { field: 'billing_auto_renew', header: 'Auto Billing' },
    { field: 'preferred_payment_method', header: 'Preferred Payment Method' },
    { field: 'billing_cycles_completed', header: 'Billing Cycles Completed' },
    { field: 'subscription_initialisation_type', header: 'Subscription Type' },
  ];

  transactionColumns: any[] = [
    { field: 'status', header: 'Status' },
    // { field: 'transaction_type', header: 'Transaction Type' },
    { field: 'transaction_description', header: 'Transaction Description' },
    { field: 'transaction_date', header: 'Transaction Date' },
    { field: 'transaction_date_due', header: 'Transaction Date Due' },
    { field: 'reference', header: 'Reference' },
    { field: 'amount_incl', header: 'Credit' },
    { field: 'amount_incl', header: 'Debit' },
    { field: 'transaction_method', header: 'Transaction Method' },
  ];

  actionColumns = [
    { field: 'action_description', header: 'Action Description' },
    { field: 'action_type', header: 'Action Type' },
    { field: 'action_date', header: 'Action Date' },
  ];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private fb: FormBuilder,
    private portalSubscriptionsService: PortalSubscriptionService,
    private portalTransactionsService: PortalTransactionsService,
    private portalActionsService: PortalActionsService,
    private portalUsersService: PortalUsersService
  ) {}

  ngOnInit(): void {
    const subscription = this.config.data.subscription;

    this.subscription.push(subscription);

    this.portalUsersService
      .getPortalUserById(subscription.portal_end_customer_id)
      .subscribe(
        (res: any) => {
          this.user = res.first_name;
          this.loading = false;
        },
        (error) => {
          console.log('Error ', error);
          this.isError = true;
        }
      );

    this.portalTransactionsService
      .getPortalTransactionsByEndCustomerId(subscription.portal_end_customer_id)
      .subscribe(
        (res: any) => {
          this.transactions = res;
          this.transactions$.next(this.transactions);
          console.log('Transactions ', this.transactions);

          this.loading = false;
        },
        (error) => {
          console.log('Error ', error);
          this.isError = true;
        }
      );

    this.portalActionsService.getPortalActions().subscribe(
      (res: any) => {
        this.actions = res.filter(
          (action: any) => action.portal_subscription_id === subscription.id
        );
        this.actions$.next(this.actions);
        this.loading = false;
      },
      (error) => {
        console.log('Error ', error);
        this.isError = true;
      }
    );
  }

  getTransactionStatusSeverity(status: any): string {
    if (status === 'completed') {
      return 'success';
    } else if (status === 'pending') {
      return 'warning';
    } else {
      return 'danger';
    }
  }
}

<div class="grid m-4" style="max-width: 100vw" *ngIf="userService.currentUser$ | async as currentUser">
  <div class="col-12">
    <div class="surface-card shadow-1 border-round p-4">
      <div class="text-3xl font-medium text-900 mb-3">Portal Settings</div>
      <div class="font-medium text-600 mb-3">
        General Captive Portal Settings
      </div>
      <app-portal-settings></app-portal-settings>
    </div>
  </div>
  <div class="col-12 mt-3">
    <p-divider><b class="text-3xl font-medium text-900 mt-3">Quick Glance</b></p-divider>
  </div>
  <div class="col-12" *ngIf="true">
    <div class="surface-card shadow-1 border-round p-4">
      <div class="text-3xl font-medium text-900 mb-3">Statistics</div>
      <div class="font-medium text-600 mb-3"></div>
      <app-subscription-statistics></app-subscription-statistics>
      <!-- <share-filter></share-filter> -->
    </div>
  </div>
  <!-- <ng-container
    *ngSwitch="
      getCurrentPortalTenantName(
        currentUser.portal_tenant_id,
        currentUser.portal_tenants || []
      )
    "
  > -->
  <!-- <ng-container *ngSwitchCase="'Benington'"> -->
  <div class="col-12 mt-3">
    <p-divider><b class="text-3xl font-medium text-900 mt-3">Packages</b></p-divider>
  </div>
  <div class="col-12">
    <div class="surface-card shadow-3 border-round p-4">
      <div class="font-medium text-600 mb-3">
        Connection Packages Currently Available on Portal
        <app-packages></app-packages>
      </div>
    </div>

    <div class="col-4" *ngIf="false">
      <div class="surface-card shadow-3 border-round p-4">
        <div class="text-3xl font-medium text-900 mb-3">Clients</div>
        <div class="font-medium text-900 mb-3">
          <!-- add end customers table component here -->
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 mt-3">
    <p-divider><b class="text-3xl font-medium text-900 mt-3">Customers</b></p-divider>
  </div>
  <div class="col-12">
    <div class="surface-card shadow-3 border-round p-4">
      <div class="font-medium text-600 mb-3">Current Customers</div>

      <app-customers></app-customers>
    </div>
  </div>
  <div class="col-12 mt-3">
    <p-divider><b class="text-3xl font-medium text-900 mt-3">Subscriptions</b></p-divider>
  </div>
  <div class="col-12">
    <div class="surface-card shadow-3 border-round p-4">
      <div class="font-medium text-600 mb-3">Current Subscriptions</div>
      <app-subscriptions></app-subscriptions>
    </div>
  </div>
  <div class="col-6" *ngIf="false">
    <p-divider><b class="text-3xl font-medium text-900 mt-3">User Actions</b></p-divider>
    <div class="surface-card shadow-3 border-round p-4">
      <div class="font-medium text-600 mb-3">User Actions</div>
      <app-user-actions></app-user-actions>
    </div>
  </div>
  <div class="col-6" *ngIf="false">
    <p-divider><b class="text-3xl font-medium text-900 mt-3">Subscription Actions</b></p-divider>
    <div class="surface-card shadow-3 border-round p-4">
      <div class="font-medium text-600 mb-3">Subscription Actions</div>
      <app-subscription-actions></app-subscription-actions>
    </div>
  </div>
  <!-- </ng-container> -->
  <!-- </ng-container> -->
</div>
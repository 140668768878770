export enum ERoles {
  superadmin = 'superadmin',
  // admin = 'admin',
  // manager = 'manager',
  salesmanager = 'salesmanager',
  salesagent = 'salesagent',
  operationsadmin = 'operationsadmin',
  operationsmanager = 'operationsmanager',
  portaladmin = 'portaladmin',
  businesssalesagent = 'businesssalesagent',
}

export interface Roles {
  id: number;
  name: ERoles;
  description: string;
}

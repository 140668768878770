import { Component, OnInit } from '@angular/core';
import { EndCustomerCircuitsService } from 'src/app/features/main-application/_services/entities/end-customer-circuits.service';
import { SitesService } from 'src/app/features/main-application/_services/entities/sites.service';
import { EndCustomerServicesService } from '../../_services/end-customer-services.service';
import { GoogleMap } from '@angular/google-maps';

// declare const google: any;

@Component({
  selector: 'app-operations-overview',
  templateUrl: './operations-overview.component.html',
  styleUrls: ['./operations-overview.component.scss'],
})
export class OperationsOverviewComponent implements OnInit {
  mapOptions: google.maps.MapOptions = {};

  overlays: any[] = [];

  areas = [
    { name: 'Windhoek', key: 'windhoek', lat: -22.5609, lng: 17.0658 },
    { name: 'Swakopmund', key: 'swakopmund', lat: -22.6787, lng: 14.535 },
    { name: 'Walvis Bay', key: 'walvis-bay', lat: -22.957019, lng: 14.50561 },
  ];

  selectedArea: any = {
    name: 'Swakopmund',
    key: 'swakopmund',
    lat: -22.6787,
    lng: 14.535,
  };
  kmls: { url: string; options: {} }[] = [];

  constructor(
    private ss: SitesService,
    private ecss: EndCustomerServicesService
  ) {}

  ngOnInit(): void {
    this.mapOptions = {
      center: {
        lat: this.selectedArea.lat,
        lng: this.selectedArea.lng,
      },
      zoom: 13,
      // mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    this.kmls = [
      {
        url: 'https://om.stemconnect.net/api/static/demshi-coverage.kmz',
        options: {
          suppressInfoWindows: true,
          preserveViewport: true,
          map: this.mapOptions,
          zIndex: 1,
          clickable: false,

          // icon: {
        },
      },
    ];

    this.ss.getSites().subscribe((sites) => {
      console.log('sites', sites);
      sites.data.map((site: any) => {
        const offset = 0.0001; // Small offset to distinguish markers
        const marker = new google.maps.Marker({
          position: {
            lat: site.latitude + Math.random() * offset,
            lng: site.longitude + Math.random() * offset,
          },
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 7,
            fillColor: '#9EB248', // Change this to your desired color
            fillOpacity: 0.25,
            strokeWeight: 0,
          },
          title: site.name, // Assuming 'site' has a 'name' property
          // label: {
          //   text: site.name,
          //   color: '#000000', // Change this to your desired color
          //   fontSize: '12px',
          //   fontWeight: 'bold',
          // },
        });

        this.overlays.push(marker);
      });
    });

    this.ecss.getEndCustomerServices().subscribe((circuits) => {
      console.log('circuits for map', circuits);
      circuits.map((circuit: any) => {
        const offset = 0.0001; // Small offset to distinguish markers
        const circuitMarker = new google.maps.Marker({
          position: {
            lat: +circuit.latitude + Math.random() * offset,
            lng: +circuit.longitude + Math.random() * offset,
          },
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 6,
            fillColor: '#0000ff', // Change this to your desired color
            fillOpacity: 0.5,
            strokeWeight: 0.0,
          },
          title: circuit.circuit_no,
        });
        this.overlays.push(circuitMarker);
      });
    });
  }

  onMarkerClick(_t11: any) {
    console.log('marker clicked', _t11);
  }

  onAreaChange(event: any, map: GoogleMap) {
    console.log('map event', event);
    console.log('map selected', map);

    // this.selectedArea = this.areas.find((area) => area.key === event.value);

    map.panTo({
      lat: this.selectedArea.lat,
      lng: this.selectedArea.lng,
    });
    map.zoom = 13;
  }
}

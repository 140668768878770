import { Injectable } from '@angular/core';
import { FilterCondition, FilterGroup } from '../_models/filter.model';

@Injectable({
  providedIn: 'root',
})
export class QueryBuilderService {
  private operatorMap: { [key: string]: string } = {
    eq: '=',
    neq: '!=',
    gt: '>',
    lt: '<',
    contains: 'LIKE',
  };

  // Converts the filter structure to a SQL-like query string
  buildQueryString(filter: FilterGroup): string {
    return this.buildGroupString(filter);
  }

  private buildGroupString(group: FilterGroup): string {
    const conditions = group.conditions.map((condition) => {
      if (this.isFilterGroup(condition)) {
        return this.buildGroupString(condition);
      }
      return this.buildConditionString(condition);
    });

    if (conditions.length === 0) return '';
    return `(${conditions.join(` ${group.logic.toUpperCase()} `)})`;
  }

  private buildConditionString(condition: FilterCondition): string {
    if (!condition.field || !condition.operator) return '';

    let value = condition.value;
    if (condition.operator === 'contains') {
      value = `'%${value}%'`;
    } else if (typeof value === 'string') {
      value = `'${value}'`;
    }

    return `${condition.field} ${this.operatorMap[condition.operator]} ${value}`;
  }

  // Converts the filter structure to a backend-friendly object
  buildQueryObject(filter: FilterGroup): any {
    return this.buildGroupObject(filter);
  }

  private buildGroupObject(group: FilterGroup): any {
    return {
      type: 'group',
      logic: group.logic,
      conditions: group.conditions
        .map((condition) => {
          if (this.isFilterGroup(condition)) {
            return this.buildGroupObject(condition);
          }
          return this.buildConditionObject(condition);
        })
        .filter((condition) => condition !== null),
    };
  }

  private buildConditionObject(condition: FilterCondition): any {
    if (!condition.field || !condition.operator) return null;

    return {
      type: 'condition',
      field: condition.field,
      operator: condition.operator,
      value: condition.value,
    };
  }

  private isFilterGroup(
    condition: FilterCondition | FilterGroup,
  ): condition is FilterGroup {
    return (condition as FilterGroup).type === 'group';
  }
}

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { CacheService } from 'src/app/_core/_services/cache.service';

@Component({
  selector: 'app-portal-auth-za',
  templateUrl: './portal-auth-za.component.html',
  styleUrls: ['./portal-auth-za.component.scss'],
})
export class PortalAuthZaComponent implements OnInit {
  loginUrl: any;
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private cacheService: CacheService,
  ) {}

  portalAuthForm = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', Validators.required],
  });

  ngOnInit(): void {
    if (this.cacheService.getItem('login')) {
      this.loginUrl = this.cacheService.getItem('login');
    } else {
      this.loginUrl = 'https://mtdhotspot.stemconnect.net/login';
    }
  }

  callbackHandler() {
    console.log('callback');
  }

  onAuthSubmit() {
    console.log('form values', this.portalAuthForm.value);
    console.log('form valid', this.portalAuthForm.valid);

    if (this.portalAuthForm.valid) {
      const params = new URLSearchParams({
        username: this.portalAuthForm.value.username ?? '',
        password: this.portalAuthForm.value.password ?? '',
        dst: 'https://mtdnetworx.co.za',
        popup: 'true',
      });

      const options = {
        params,
      };
      console.log('options', options);

      this.http
        .jsonp(
          `${this.loginUrl}?${params.toString()}`,
          'callbackHandler', // Specify the name of the callback query parameter
        )
        .subscribe(
          (res) => {
            console.log('res', res);
          },
          (err) => {
            console.log('err', err);
            // todo: add logic to handle error
            window.location.href = `${this.loginUrl}?dst=https://mtdnetworx.co.za&popup=true`;
          },
        );
    } else {
      console.log('form invalid');
      this.portalAuthForm.markAllAsTouched();

      for (const control of Object.keys(this.portalAuthForm.controls)) {
        this.portalAuthForm.get(control)?.markAsDirty();
      }
    }
  }
}

import { Injectable } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';

import { IspsService } from './entities/isps.service';
import { VnosService } from './entities/vnos.service';
import { RegionsService } from './entities/regions.service';
import { VlansService } from './entities/vlans.service';
import { ApiService } from '../../../_core/_services/api.service';
import { ProductsService } from './entities/products.service';
import { EndCustomerCircuitsService } from './entities/end-customer-circuits.service';
import { EndCustomersService } from './entities/end-customers.service';
import { ContactsService } from './entities/contacts.service';
import { EquipmentTypesService } from './entities/equipment-types.service';
import { SitesService } from './entities/sites.service';
import { DevicesService } from './entities/devices.service';
import { IspCircuitsService } from './entities/isp-circuits.service';

@Injectable({
  providedIn: 'root',
})
export class RelationshipService {
  // relationship endpoints
  // ----------------------

  vnoregionQuery$: any;
  vnoregion: any = [];
  vnoregion$: Subject<any> = new Subject<any>();

  productcontactQuery$: any;
  productcontact: any = [];
  productcontact$: Subject<any> = new Subject<any>();

  productequipmenttypeQuery$: any;
  productequipmenttype: any = [];
  productequipmenttype$: Subject<any> = new Subject<any>();

  regioncontactQuery$: any;
  regioncontact: any = [];
  regioncontact$: Subject<any> = new Subject<any>();

  vlanregionQuery$: any;
  vlanregion: any = [];
  vlanregion$: Subject<any> = new Subject<any>();

  vnoispQuery$: any;
  vnoisp: any = [];
  vnoisp$: Subject<any> = new Subject<any>();

  relatedstringEntity: any[] = [];

  // todo: change the api endpoints to allow the many to many ids to be submitted as lists instead of having relationship endpoints
  // todo: genaralise the many to many relationship endpoints or remove them with a suited alternative

  // related
  // -------

  ispsRelated$: Subject<any> = new Subject<any>();
  ispsRelated: any;

  vlansRelated$: Subject<any> = new Subject<any>();
  vlansRelated: any;

  vnosRelated: any;
  vnosRelated$: Subject<any> = new Subject<any>();

  productsRelated: any;
  productsRelated$: Subject<any> = new Subject<any>();

  endCustomerCircuitsRelated: any;
  endCustomerCircuitsRelated$: Subject<any> = new Subject<any>();

  endCustomersRelated: any;
  endCustomersRelated$: Subject<any> = new Subject<any>();

  contactsRelated: any;
  contactsRelated$: Subject<any> = new Subject<any>();

  sitesRelated: any;
  sitesRelated$: Subject<any> = new Subject<any>();

  devicesRelated: any;
  devicesRelated$: Subject<any> = new Subject<any>();

  equipmentTypesRelated: any;
  equipmentTypesRelated$: Subject<any> = new Subject<any>();

  regionsRelated: any;
  regionsRelated$: Subject<any> = new Subject<any>();

  relationshipArray: string[] = [
    // 'vnoregion',
    // 'vnoisp',
    // 'productequipmenttype',
  ];

  relationshipMap = {
    //vnoregion : { data: 'vnoregion', subject: 'vnoregion$' },
    // productequipmenttype: {
    //   data: 'productequipmenttype',
    //   subject: 'productequipmenttype$',
    // },
    // vnoisp: { data: 'vnoisp', subject: 'vnoisp$' },
  };

  constructor(
    public ispsService: IspsService,
    public ispCircuitsService: IspCircuitsService,
    public vnosService: VnosService,
    public regionsService: RegionsService,
    public vlansService: VlansService,
    public productsService: ProductsService,
    public equipmentTypesService: EquipmentTypesService,
    public sitesService: SitesService,
    public devicesService: DevicesService,
    public endCustomersService: EndCustomersService,
    public endCustomerCircuitsService: EndCustomerCircuitsService,
    public contactsService: ContactsService,
    public api: ApiService
  ) {}

  // leave as is
  // getvnoisp() {
  //   this.vnoispQuery$ = this.api.getAPI<any>('vnoisp').subscribe(
  //     (res) => {
  //       //console.log('vnoisp', res);
  //       this.vnoisp = res.body;
  //       this.vnoisp$.next(this.vnoisp);
  //     },
  //     (err) => {
  //       //console.log('err', err);
  //     }
  //   );
  // }

  // getvnoregion() {
  //   this.vnoregionQuery$ = this.api.getAPI<any>('vnoregion').subscribe(
  //     (res) => {
  //       //console.log('vnoregion', res);
  //       this.vnoregion = res.body;
  //       this.vnoregion$.next(this.vnoregion);
  //     },
  //     (err) => {
  //       //console.log('err', err);
  //     }
  //   );
  // }

  // getproductcontact() {
  //   this.productcontactQuery$ = this.api
  //     .getAPI<any>('productcontact')
  //     .subscribe(
  //       (res) => {
  //         //console.log('productcontact', res);
  //         this.productcontact = res.body;
  //         this.productcontact$.next(this.productcontact);
  //       },
  //       (err) => {
  //         //console.log('err', err);
  //       }
  //     );
  // }

  // getproductequipmenttype() {
  //   this.productequipmenttypeQuery$ = this.api
  //     .getAPI<any>('productequipmenttype')
  //     .subscribe(
  //       (res) => {
  //         //console.log('productequipmenttype', res);
  //         this.productequipmenttype = res.body;
  //         this.productequipmenttype$.next(this.productequipmenttype);
  //       },
  //       (err) => {
  //         //console.log('err', err);
  //       }
  //     );
  // }

  // getregioncontact() {
  //   this.regioncontactQuery$ = this.api.getAPI<any>('regioncontact').subscribe(
  //     (res) => {
  //       //console.log('regioncontact', res);
  //       this.regioncontact = res.body;
  //       this.regioncontact$.next(this.regioncontact);
  //     },
  //     (err) => {
  //       //console.log('err', err);
  //     }
  //   );
  // }

  //getAll() {
  // todo: many to one auto mapping
  // todo: many to many auto mapping
  // fixme: improve and adapt to changes when relationships change

  // regions

  // combineLatest([
  //   this.regionsService.regions$,
  //   this.vnosService.vnos$,
  //   this.vnoregion$,
  // ]).subscribe(([regions, vnos, regionsvnoscombined]) => {
  //   // base is regions
  //   // mapped should be vnos

  //   const mappedRegions = regions.map((region: any) => {
  //     const relatedVnoIds = regionsvnoscombined

  //       .filter((regionsvno: any) => {
  //         if (regionsvno.region_id === region.id) {
  //           return regionsvno;
  //         }
  //       })
  //       .map((regionsvno: any) => {
  //         return regionsvno.vno_id;
  //       });

  //     const relatedVnos = vnos.filter((vno: any) => {
  //       if (relatedVnoIds.includes(vno.id)) {
  //         return vno;
  //       }
  //     });

  //     return { ...region, vnos: relatedVnos };
  //   });

  //   this.regionsRelated = mappedRegions;
  //   this.regionsRelated$.next(this.regionsRelated);
  // });

  // endCustomerCircuits
  // combineLatest([
  //   this.endCustomerCircuitsService.endCustomerCircuits$,
  //   this.endCustomersService.endCustomers$,
  //   this.vlansService.vlans$,
  // ]).subscribe(([endCustomerCircuits, endCustomers, vlans]) => {
  //   // base is endCustomerCircuits
  //   // mapped should be endCustomers

  //   let mappedEndCustomerCircuits = endCustomerCircuits.map(
  //     (endCustomerCircuit: any) => {
  //       const endCustomerSingle = endCustomers.filter((endCustomer: any) => {
  //         if (endCustomerCircuit.end_customer_id === endCustomer.id) {
  //           return endCustomer;
  //         }
  //       });
  //       return { ...endCustomerCircuit, end_customer: endCustomerSingle[0] };
  //     }
  //   );

  //   mappedEndCustomerCircuits = mappedEndCustomerCircuits.map(
  //     (endCustomerCircuit: any) => {
  //       const vlanSingle = vlans.filter((vlan: any) => {
  //         if (endCustomerCircuit.vlan_id === vlan.id) {
  //           return vlan;
  //         }
  //       });
  //       return { ...endCustomerCircuit, vlan: vlanSingle[0] };
  //     }
  //   );

  //   this.endCustomerCircuitsRelated = mappedEndCustomerCircuits;
  //   this.endCustomerCircuitsRelated$.next(mappedEndCustomerCircuits);
  // });

  // endCustomers
  // combineLatest([
  //   this.endCustomersService.endCustomers$,
  //   this.ispsService.isps$,
  // ]).subscribe(([endCustomers, isps]) => {
  //   // base is endCustomers
  //   // mapped should be isps

  //   const mappedEndCustomers = endCustomers.map((endCustomer: any) => {
  //     const ispSingle = isps.filter((isp: any) => {
  //       if (endCustomer.isp_id === isp.id) {
  //         return isp;
  //       }
  //     });
  //     return { ...endCustomer, isp: ispSingle[0] };
  //   });

  //   this.endCustomersRelated = mappedEndCustomers;
  //   this.endCustomersRelated$.next(mappedEndCustomers);
  // });

  // products
  // combineLatest([
  //   this.productsService.products$,
  //   this.vnosService.vnos$,
  //   this.equipmentTypesService.equipmentTypes$,
  //   this.productequipmenttype$,
  // ]).subscribe(
  //   ([products, vnos, equipmenttypes, productequipmenttypecombined]) => {
  //     // base is products
  //     // mapped should be vnos

  //     const mappedProducts = products.map((product: any) => {
  //       const vnoSingle = vnos.filter((vno: any) => {
  //         if (product.vno_id === vno.id) {
  //           return vno;
  //         }
  //       });
  //       return { ...product, vno: vnoSingle[0] };
  //     });
  //     this.productsRelated = mappedProducts;
  //     this.productsRelated$.next(mappedProducts);

  //     // base is products
  //     // mapped should be equipmenttypes

  //     const mappedProducts2 = mappedProducts.map((product: any) => {
  //       const relatedEquipmentTypeIds = productequipmenttypecombined
  //         .filter(
  //           (productequipmenttype: any) =>
  //             productequipmenttype.product_id === product.id
  //         )
  //         .map(
  //           (productequipmenttype: any) =>
  //             productequipmenttype.equipment_type_id
  //         );
  //       const relatedEquipmentTypes = equipmenttypes.filter(
  //         (equipmenttype: any) =>
  //           relatedEquipmentTypeIds.includes(equipmenttype.id)
  //       );
  //       // //console.log('mappedEquipmentTypes', relatedEquipmentTypes);
  //       product.equipmenttypes = relatedEquipmentTypes;
  //       return { ...product, equipment_types: relatedEquipmentTypes };
  //     });

  //     this.productsRelated = mappedProducts2;
  //     this.productsRelated$.next(mappedProducts2);
  //     //console.log('mappedProducts2', mappedProducts2);
  //   }
  // );

  // isps
  // combineLatest([
  //   this.ispsService.isps$,
  //   this.vnosService.vnos$,
  //   this.vnoisp$,
  // ]).subscribe(([isps, vnos, vnoispcombined]) => {
  //   // base is isps
  //   // mapped should be vnos

  //   const mappedIsps = isps.map((isp: any) => {
  //     const relatedVnoIds = vnoispcombined
  //       .filter((ispvno: any) => ispvno.isp_id === isp.id)
  //       .map((ispvno: any) => ispvno.vno_id);
  //     const relatedVnos = vnos.filter((vno: any) =>
  //       relatedVnoIds.includes(vno.id)
  //     );
  //     // //console.log('mappedVnos', relatedVnos);
  //     isp.vnos = relatedVnos;
  //     return { ...isp, vnos: relatedVnos };
  //   });

  //   this.ispsRelated = mappedIsps;
  //   this.ispsRelated$.next(mappedIsps);
  // });

  // vlans
  // combineLatest([
  //   this.vlansService.vlans$,
  //   this.ispsService.isps$,
  //   this.productsService.products$,
  //   this.regionsService.regions$,
  //   // this.vlansvnos$,
  //   // this.vlansisps$,
  //   // this.vlanregion$,
  // ]).subscribe(
  //   ([
  //     vlans,
  //     isps,
  //     products,
  //     regions,
  //     // vlanregioncombined,
  //   ]) => {
  //     // base is vlans
  //     // mapped should be isps

  //     const mappedVlans = vlans.map((vlan: any) => {
  //       const ispSingle = isps.filter((isp: any) => {
  //         if (vlan.isp_id === isp.id) {
  //           return isp;
  //         }
  //       });
  //       return { ...vlan, isp: ispSingle[0] };
  //     });

  //     const mappedVlans2 = mappedVlans.map((vlan: any) => {
  //       const productSingle = products.filter((product: any) => {
  //         if (vlan.product_id === product.id) {
  //           return product;
  //         }
  //       });
  //       return { ...vlan, product: productSingle[0] };
  //     });

  //     const mappedVlans3 = mappedVlans2.map((vlan: any) => {
  //       const regionSingle = regions.filter((region: any) => {
  //         if (vlan.region_id === region.id) {
  //           return region;
  //         }
  //       });
  //       return { ...vlan, region: regionSingle[0] };
  //     });

  //     this.vlansRelated = mappedVlans3;
  //     this.vlansRelated$.next(mappedVlans3);
  //   }
  // );

  // // contacts
  // combineLatest([
  //   this.contactsService.contacts$,
  //   // this.ispsService.isps$,
  //   // this.regionsService.regions$,
  //   this.vnosService.vnos$,
  // ]).subscribe(([contacts, vnos]) => {
  //   // base is contacts
  //   // mapped should be isps
  //   const mappedContacts = contacts.map((contact: any) => {
  //     const vnoSingle = vnos.filter((vno: any) => {
  //       if (contact.vno_id === vno.id) {
  //         return vno;
  //       }
  //     });
  //     return { ...contact, vno: vnoSingle[0] };
  //   });

  //   this.contactsRelated = mappedContacts;
  //   this.contactsRelated$.next(mappedContacts);
  // });

  // sites
  // combineLatest([this.sitesService.sites$, this.vnosService.vnos$]).subscribe(
  //   ([sites, vnos]) => {
  //     // base is sites
  //     // mapped should be vnos
  //     const mappedSites = sites.map((site: any) => {
  //       const vnoSingle = vnos.filter((vno: any) => {
  //         if (site.vno_id === vno.id) {
  //           return vno;
  //         }
  //       });
  //       return { ...site, vno: vnoSingle[0] };
  //     });

  //     this.sitesRelated = mappedSites;
  //     this.sitesRelated$.next(mappedSites);
  //   }
  // );

  // equipment types

  // devices
  // combineLatest([
  //   this.devicesService.devices$,
  //   this.endCustomerCircuitsService.endCustomerCircuits$,
  //   this.equipmentTypesService.equipmentTypes$,
  // ]).subscribe(([devices, endcustomercircuits, equipmenttypes]) => {
  //   // base is devices
  //   // mapped should be endcustomercircuits
  //   const mappedDevices = devices.map((device: any) => {
  //     const endCustomerCircuitSingle = endcustomercircuits.filter(
  //       (endcustomercircuit: any) => {
  //         if (device.end_customer_circuit_id === endcustomercircuit.id) {
  //           return endcustomercircuit;
  //         }
  //       }
  //     );
  //     return {
  //       ...device,
  //       end_customer_circuit: endCustomerCircuitSingle[0],
  //     };
  //   });

  //   this.devicesRelated = mappedDevices;
  //   this.devicesRelated$.next(mappedDevices);

  //   // base is devices
  //   // mapped should be equipmenttypes

  //   const mappedDevices2 = mappedDevices.map((device: any) => {
  //     const equipmentTypeSingle = equipmenttypes.filter(
  //       (equipmenttype: any) => {
  //         if (device.equipment_type_id === equipmenttype.id) {
  //           return equipmenttype;
  //         }
  //       }
  //     );

  //     return { ...device, equipment_type: equipmentTypeSingle[0] };
  //   });

  //   this.devicesRelated = mappedDevices2;
  //   this.devicesRelated$.next(mappedDevices2);
  // });
  //}

  getRelationshipId(
    relatedEntity: any,
    relatedEntityName: string,
    relatedEntityId: number,
    baseEntityId: number
  ) {
    // //console.log(relatedEntity[relatedEntityName].endpoint);

    this.relatedstringEntity = this.relationshipArray.filter((relationship) => {
      if (relationship.includes(relatedEntity[relatedEntityName].endpoint)) {
        //console.log('found match');
        return relationship;
      } else {
        //console.log('no match found');
        return null;
      }
    });

    let dataList: any[] = this[
      this.relatedstringEntity[0] as keyof this
    ] as any;

    dataList = dataList.filter((dataItem: any) => {
      if (
        dataItem[relatedEntity[relatedEntityName].base_id] == baseEntityId &&
        dataItem[relatedEntity[relatedEntityName].related_id] == relatedEntityId
      ) {
        return dataItem;
      }
    });

    const relationshipIdToDel = dataList[0].id;

    //console.log('dataList', dataList);

    //console.log('relatedEntity', relatedEntity);
    //console.log('relatedEntityName', relatedEntityName);
    //console.log('relatedEntityId', relatedEntityId);
    return relationshipIdToDel;
  }

  addRelationship(endpoint: string, data: any) {
    // this is a generalised function to add a relationship between two tables
    this.api.postAPI(endpoint, data).subscribe(
      (res) => {
        //console.log('res', res);
      },
      (err) => {
        //console.log('err', err);
      }
    );
  }

  deleteRelationship(endpoint: string, id: number) {
    // this is a generalised function to delete a relationship between two tables
    this.api.deleteAPI(endpoint, id).subscribe(
      (res) => {
        //console.log('res', res);
      },
      (err) => {
        //console.log('err', err);
      }
    );
  }

  modifyRelationships(
    relatedEntity: any,
    relatedEntityName: string,
    originalForm: any,
    submittedForm: any
  ) {
    // this is a generalised function to find the id of a relationship between two tables
    // this is used to check if a relationship already exists
    // for example, to check if a product already has a relationship with an equipment type
    // if it does, then we don't want to add another relationship
    // we want to delete

    //console.log('relatedEntity', relatedEntity);
    //console.log('relatedEntityName', relatedEntityName);

    let newIds: any[] = [];
    let oldIds: any[] = [];
    let removedIds: any[] = [];
    let addedIds: any[] = [];

    // original form will have object under vnos and new form will have array of ids
    // we need to compare the two

    newIds = submittedForm[relatedEntityName];
    oldIds = originalForm[relatedEntityName].map((entity: any) => entity.id);
    removedIds = oldIds.filter((oldId: any) => !newIds.includes(oldId));
    addedIds = newIds.filter((newId: any) => !oldIds.includes(newId));

    //console.log('newIds', newIds);
    //console.log('oldIds', oldIds);
    //console.log('removedIds', removedIds);

    const allIds = newIds.concat(removedIds);

    allIds.forEach((relatedEntityId: any) => {
      if (removedIds.includes(relatedEntityId) == true) {
        //console.log('delete', relatedEntityId);

        const relationshipId = this.getRelationshipId(
          relatedEntity,
          relatedEntityName,
          relatedEntityId,
          originalForm.id
        );

        this.deleteRelationship(
          relatedEntity[relatedEntityName].endpoint,
          // todo: this is not the correct id, find id from the list of ids
          relationshipId
        );
      } else if (addedIds.includes(relatedEntityId) == true) {
        //console.log('add');
        this.addRelationship(relatedEntity[relatedEntityName].endpoint, {
          [relatedEntity[relatedEntityName].base_id]: originalForm.id,
          [relatedEntity[relatedEntityName].related_id]: relatedEntityId,
        });
      }
    });
  }
}

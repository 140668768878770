<div class="flex flex-column h-full min-h-full">
  <ng-container>
    <div class="container">
      <div *ngIf="loading; else showTransactions">
        <div class="mb-3">
          <span class="font-bold text-2xl text-900">Loading Details...</span>
        </div>
        <p-progressBar styleClass="align-self-center" mode="indeterminate">
        </p-progressBar>
      </div>

      <ng-template #showTransactions>
        <div *ngIf="isError">
          <p>An error occurred while fetching data</p>
        </div>

        <ng-container *ngIf="transactions$ | async"> </ng-container>
        <!-- todo: move outside of tab view -->
        <share-subscription-action-bar
          [subscription]="subscription[0]"
        ></share-subscription-action-bar>
        <share-subscription-details
          [subscription]="subscription[0]"
        ></share-subscription-details>
        <span class="text-900 font-medium text-3xl px-6">Transactions</span>
        <div class="lg:px-6 px-1 py-5">
          <div class="surface-card p-4 shadow-2 border-round">
            <p-table
              #transactionsTable
              [value]="transactions"
              scrollHeight="40vh"
              [scrollable]="true"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th
                    *ngFor="let col of transactionColumns"
                    [pSortableColumn]="col.field"
                  >
                    <div class="flex align-items-center">
                      {{ col.header }}
                    </div>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-transaction>
                <tr
                  [pSelectableRow]="transaction"
                  [pContextMenuRow]="transaction"
                >
                  <td *ngFor="let col of transactionColumns">
                    <div
                      class="flex align-items-center"
                      *ngIf="
                        col.field != 'amount_incl' && col.field != 'status'
                      "
                    >
                      {{ transaction[col.field] }}
                    </div>

                    <div
                      class="flex align-items-center text-red-500"
                      *ngIf="
                        col.field == 'amount_incl' &&
                        transaction.transaction_type == 'debit' &&
                        col.header == 'Debit'
                      "
                    >
                      {{ transaction[col.field] | currency : "R" }}
                    </div>
                    <div
                      class="flex align-items-center text-green-500"
                      *ngIf="
                        col.field == 'amount_incl' &&
                        transaction.transaction_type == 'credit' &&
                        col.header == 'Credit'
                      "
                    >
                      {{
                        transaction[col.field]
                          | currency : "ZAR" : "symbol-narrow"
                      }}
                    </div>
                    <div
                      class="flex align-items-center"
                      *ngIf="col.field == 'status'"
                    >
                      <p-tag
                        [value]="transaction[col.field] | uppercase"
                        [severity]="
                          getTransactionStatusSeverity(transaction[col.field])
                        "
                      >
                      </p-tag>
                    </div>
                  </td>
                </tr>
              </ng-template>
              <!-- <ng-template pTemplate="emptymessage">
         <tr>
           <td
             [attr.colspan]="this.getNumberofColumns()"
             class="align-content-center"
           >
             <p>
               There are no records to show. You might be disconnected or offline.
             </p>
           </td>
         </tr>
       </ng-template> -->
            </p-table>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-container>
</div>

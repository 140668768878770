import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ApiService } from 'src/app/_core/_services/api.service';
import { NetworkServices } from '../_models/network-services.model';

@Injectable({
  providedIn: 'root',
})
export class NetworkServicesService {
  constructor(private api: ApiService) {}

  getNetworkServices() {
    return this.api.getAPI('network-portal/services').pipe(
      map((res: any) => {
        console.log('nmmp services', res);
        return res.body.results;
      })
    );
  }
}

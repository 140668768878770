import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ApiService } from 'src/app/_core/_services/api.service';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  constructor(private api: ApiService) {}

  getAll() {
    return this.api.getAPI('roles').pipe(
      map((res: any) => {
        return res.body.results;
      })
    );
  }

  create(role: any) {
    return this.api.postAPI('roles', role).pipe(
      map((data: any) => {
        return data.body;
      })
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NotificationService } from 'src/app/_share/_services/notification.service';
import { ConfigurationService } from 'src/app/features/main-application/_services/configuration.service';

@Component({
  selector: 'app-device-configuration-dialog',
  templateUrl: './device-configuration-dialog.component.html',
  styleUrls: ['./device-configuration-dialog.component.scss'],
})
export class DeviceConfigurationDialogComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private cs: ConfigurationService,
    public notificationService: NotificationService
  ) {}

  configurationForm: FormGroup = this.fb.group({
    circuit_no: ['', [Validators.required]],
    device_serial_no: ['', [Validators.required]],
    wifi_ssid: [
      '',
      [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9 _-]{6,32}$/), // Allow alphanumeric, space, dash, and underscore with a length of 6 to 32 characters
      ],
    ],
    wifi_password: [
      '',
      [
        Validators.required,
        Validators.pattern(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,63}$/
        ),
      ],
    ],
    wifi_password_confirm: [''],
  });

  errorMessage: string = '';

  ngOnInit(): void {
    console.log('this is the data for the device', this.config.data);
    this.config.data && this.configurationForm.patchValue(this.config.data);

    this.errorMessage = '';

    this.configurationForm.valueChanges.subscribe(() => {
      this.errorMessage = '';
    });
  }

  onSubmit() {
    if (this.configurationForm.invalid) {
      this.errorMessage = 'Please fill out all required fields';
      return;
    }
    if (this.configurationForm.valid) {
      console.log('Form Submitted', this.configurationForm.value);
      this.cs.updateDeviceConfiguration(this.configurationForm.value).subscribe(
        (res) => {
          console.log('Configuration Updated', res);
          this.errorMessage = '';
          this.ref.close(this.configurationForm.value);
          this.notificationService.mainSuccess(
            'Configuration updated successfully'
          );
        },
        (err) => {
          console.error('Error updating configuration', err);
          this.errorMessage = 'Error updating configuration';
        }
      );
    }
  }
}

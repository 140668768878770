<div class="flex flex-column h-full">
  <div class="surface-card shadow-1 p-3 z-0 flex-1">
    <div class="grid nested-grid" style="max-width: 100vw">
      <div class="col-12">
        <div class="grid">
          <div class="col-12">
            <p-tabView>
              <!-- <p-tabPanel header="Overview"> </p-tabPanel> -->
              <p-tabPanel *ngFor="let tab of this.tabs" [header]="tab.name">
                <div [ngSwitch]="tab.key">
                  <ng-container *ngSwitchCase="'overview'">
                    <div class="col-12 md:col-12">
                      <div
                        class="justify-content-center align-items-center text-center h-full min-h-full flex flex-column"
                      >
                        <h2 class="flex-1">
                          Check back soon! Work in progress
                        </h2>
                      </div>
                      <!-- <app-conversation-view></app-conversation-view> -->
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'products-and-promotions'">
                    <div class="col-12 md:col-12">
                      <app-products-promotions></app-products-promotions>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'billing'">
                    <div class="col-12 md:col-12">
                      <app-services-billing></app-services-billing>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'customers'">
                    <app-services-customers></app-services-customers>
                  </ng-container>
                  <ng-container *ngSwitchCase="'orders'">
                    <app-orders></app-orders>
                  </ng-container>
                  <ng-container *ngSwitchCase="'charges'">
                    <app-services-charges></app-services-charges>
                  </ng-container>
                  <ng-container *ngSwitchCase="'management'">
                    <app-services-management></app-services-management>
                  </ng-container>
                </div> </p-tabPanel
            ></p-tabView>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 


 -->
<p-contextMenu #cm [model]="items"></p-contextMenu>
<p-confirmDialog
  key="delete"
  rejectButtonStyleClass="p-button-outlined"
></p-confirmDialog>
<p-table
  styleClass="p-datatable-striped"
  #customersTable
  [value]="(customers$ | async) || []"
  scrollHeight="40vh"
  [scrollable]="true"
  [contextMenu]="cm"
  [(contextMenuSelection)]="selectedCustomer"
  [globalFilterFields]="[
    'first_name',
    'last_name',
    'identification_reference',
    'identification_type',
    'mobile_number',
    'email',
    'complex_building',
    'unit_number',
    'street_address',
    'city',
    'province',
    'postal_code',
    'suburb',
    'postal_address'
  ]"
>
  <ng-template pTemplate="caption">
    <div class="flex">
      <button
        pButton
        label="Clear"
        class="p-button-outlined"
        icon="pi pi-filter-slash"
        (click)="clear(customersTable)"
      ></button>
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input
          #inputField
          pInputText
          type="text"
          (input)="customersTable.filterGlobal(inputField.value, 'contains')"
          placeholder="Search keyword"
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        <div class="flex align-items-center">
          {{ col.header }}
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-customer>
    <tr
      #row
      style="cursor: pointer"
      [pSelectableRow]="customer"
      [pContextMenuRow]="customer"
      (click)="showUserDetailsDialog(customer, $event)"
    >
      <td *ngFor="let col of columns">
        <ng-container [ngSwitch]="col.field">
          <div class="flex align-items-center" *ngSwitchCase="'active'">
            <p-tag
              [value]="customer[col.field] ? 'ACTIVE' : 'INACTIVE'"
              [severity]="customer[col.field] ? 'success' : 'danger'"
            ></p-tag>
          </div>
          <div class="flex align-items-center" *ngSwitchDefault>
            {{ customer[col.field] }}
          </div>
        </ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td
        [attr.colspan]="this.getNumberofColumns()"
        class="align-content-center"
      >
        <p-progressBar
          styleClass="align-self-center"
          *ngIf="isLoading"
          mode="indeterminate"
        >
        </p-progressBar>
        <div *ngIf="!isLoading">
          <p>
            There are no records to show. You might be disconnected or offline.
          </p>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="#errorMessage">
    <div *ngIf="isError">
      <p>There was an error fetching the data</p>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <tr>
      <td
        [attr.colspan]="this.getNumberofColumns()"
        class="align-content-center justify-content-center"
      >
        <p>
          <span class="text-600 text-sm">Total Customers: </span>
          {{ customersTable._totalRecords }}
        </p>
      </td>
    </tr>
  </ng-template>
</p-table>
<p-confirmDialog header="Delete"></p-confirmDialog>

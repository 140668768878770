import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {
  orderSummary: any = [
    {
      title: 'Service Inquiry',
      value: 10,
      color: 'indigo',
    },
    {
      title: 'Service Feasible',
      value: 10,
      color: 'blue',
    },
    {
      title: 'Services Ordered and Confirmed',
      value: 20,
      color: 'orange',
    },
    {
      title: 'Installation Completed',
      value: 22,
      color: 'green',
    },
    {
      title: 'Service Activated',
      value: 50,
      color: 'purple',
    },
  ];

  total: number = 0;
  confirmed: number = 0;

  constructor() {}

  ngOnInit(): void {
    this.total = this.orderSummary.reduce((acc: number, item: any) => {
      return acc + item.value;
    }, 0);
    this.confirmed = this.orderSummary
      .filter(
        (item: any) =>
          item.title !== 'Service Activated' &&
          item.title !== 'Installation Completed'
      )
      .reduce((acc: number, item: any) => {
        return acc + item.value;
      }, 0);
  }
}

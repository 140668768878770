<div *ngIf="rowData">
  <div [ngSwitch]="selectedTab">
    <div *ngSwitchCase="'Devices'">
      <ul class="list-none p-2 m-0 border-300">
        <li class="flex align items-center flex-wrap border-300 py-3">
          <div class="text-500 w-full md:w-6 font-medium text-center">
            Serial Number
          </div>
          <div class="text-900 w-full md:w-6 text-center">
            {{ rowData.serial_no }}
          </div>
        </li>
        <li
          class="flex align-items-center flex-wrap border-top-1 border-300 py-1 border-bottom-1"
        >
          <div class="text-500 w-full md:w-6 font-medium text-center">
            Status
          </div>
          <div class="text-900 w-full md:w-6 text-center">
            <p-tag
              styleClass="p-2 text-lg"
              severity="{{
                getPhysicalStatusSeverity(rowData.physical_status)
              }}"
              value="{{ rowData.physical_status | uppercase }}"
              icon="{{ getPhysicalStatusIcon(rowData.physical_status) }}"
            ></p-tag>
          </div>
        </li>
        <li
          class="flex align-items-center flex-wrap border-300 py-3 border-bottom-1"
        >
          <div class="text-500 w-full md:w-6 font-medium text-center">OLT</div>
          <div class="text-900 w-full md:w-6 text-center">
            {{ rowData.olt_reference }}
          </div>
        </li>
        <li class="flex align items-center flex-wrap border-300 py-3">
          <div class="text-500 w-full md:w-6 font-medium text-center">ONT</div>
          <div class="text-900 w-full md:w-6 text-center">
            {{ rowData.equipment_type.manufacturer }} -
            {{ rowData.equipment_type.model }}
          </div>
        </li>
        <li class="flex align items-center flex-wrap border-300 py-3">
          <div class="text-500 w-full md:w-6 font-medium text-center">
            Optical Rx
          </div>
          <div class="text-900 w-full md:w-6 text-center">
            {{ rowData.rxoptical }} dBm
          </div>
        </li>
        <li class="flex align items-center flex-wrap border-300 py-3">
          <div class="text-500 w-full md:w-6 font-medium text-center">
            Optical Tx
          </div>
          <div class="text-900 w-full md:w-6 text-center">
            {{ rowData.txoptical }} dBm
          </div>
        </li>
        <li
          class="flex align items-center flex-wrap border-300 py-3 border-bottom-1"
        >
          <div class="text-500 w-full md:w-6 font-medium text-center">
            Optical OLT Rx
          </div>
          <div class="text-900 w-full md:w-6 text-center">
            {{ rowData.oltrxoptical }} dBm
          </div>
        </li>
        <li class="flex align items-center flex-wrap border-300 py-3">
          <div class="text-500 w-full md:w-6 font-medium text-center">
            WiFi SSID (2.4Ghz)
          </div>
          <div class="text-900 w-full md:w-6 text-center">
            {{ rowData.end_customer_circuit.wifi_ssid }}
          </div>
        </li>
        <li
          class="flex align items-center flex-wrap border-300 py-3 border-bottom-1"
        >
          <div class="text-500 w-full md:w-6 font-medium text-center">
            WiFi SSID (5Ghz)
          </div>
          <div class="text-900 w-full md:w-6 text-center">
            {{ rowData.end_customer_circuit.wifi_ssid }}-5
          </div>
        </li>
        <li class="flex align items-center flex-wrap border-300 py-3">
          <div class="text-500 w-full md:w-6 font-medium text-center">
            PPPoE Username
          </div>
          <div class="text-900 w-full md:w-6 text-center">
            {{ rowData.end_customer_circuit.pppoe_username }}
          </div>
        </li>
        <li
          class="flex align items-center flex-wrap border-300 py-3 border-bottom-1"
        >
          <div class="text-500 w-full md:w-6 font-medium text-center">
            PPPoE Password
          </div>
          <div class="text-900 w-full md:w-6 text-center">
            {{ rowData.end_customer_circuit.pppoe_password }}
          </div>
        </li>
        <li class="flex align items-center flex-wrap border-300 py-3">
          <div class="text-500 w-full md:w-6 font-medium text-center">
            Updated
          </div>
          <div class="w-full md:w-6 text-center text-500">
            {{ rowData.date_updated | dateAsAgo }}
          </div>
        </li>
      </ul>

      <div class="p-2 border-1 border-300">
        <span class="p-buttonset mx-1">
          <button
            pButton
            class="p-button-info"
            icon="pi pi-wrench"
            iconPos="left"
            (click)="showDeviceConfiguration()"
          ></button>
        </span>
        <span class="p-buttonset mx-1">
          <button
            pButton
            class="p-button-success"
            icon="pi pi-refresh"
            iconPos="left"
          ></button>
        </span>
      </div>
    </div>

    <div>
      <div class="card h-20rem" *ngIf="false">
        <!-- <h4>Temperature °C:</h4> -->
        <p-chart
          type="line"
          [data]="trafficData"
          [options]="basicOptions"
        ></p-chart>
      </div>
      <div class="card" *ngIf="false">
        <!-- <h4>Ethernet Status:</h4> -->
        <p-chart
          type="line"
          [data]="signalData"
          [options]="basicOptions"
        ></p-chart>
      </div>
    </div>

    <div *ngSwitchCase="'End Customer Circuits'">
      <h4><strong class="pr-3">Wifi SSID:</strong>{{ rowData.wifi_ssid }}</h4>
      <h4>
        <strong class="pr-3">Mac Address:</strong
        >{{ circuitDevice.mac_address }}
      </h4>
      <h4>
        <strong class="pr-3">Manufacturer:</strong>
        {{ equipmentTypes.manufacturer }}
      </h4>
      <h4>
        <strong class="pr-3">Model:</strong>
        {{ equipmentTypes.model }}
      </h4>
      <div class="card">
        <h4>Freq per band:</h4>
        <p-chart
          type="line"
          [data]="trafficData"
          [options]="basicOptions"
        ></p-chart>
      </div>
      <div class="card">
        <h4>Ethernet Status:</h4>
        <p-chart
          type="line"
          [data]="signalData"
          [options]="basicOptions"
        ></p-chart>
      </div>
    </div>
    <div *ngSwitchCase="'ISPs'">
      <h4><strong class="pr-3">Selected ISP:</strong>{{ rowData.name }}</h4>
      <!-- I'm not sure about the contact and account details -->
      <h4><strong class="pr-3">Contact:</strong></h4>
      <p-divider></p-divider>
      <h2>Account Information</h2>
      <p-divider></p-divider>
    </div>
    <div *ngSwitchDefault></div>
  </div>
</div>

<p-splitter
  [panelSizes]="[20, 80]"
  [minSizes]="[10, 0]"
  styleClass="mb-5"
  *ngIf="false"
>
  <ng-template pTemplate>
    <div class="col flex align-items-center justify-content-center">
      Panel 1
    </div>
  </ng-template>
  <ng-template pTemplate>
    <p-splitter layout="vertical" [panelSizes]="[15, 85]">
      <ng-template pTemplate>
        <div class="col flex align-items-center justify-content-center">
          Panel 2
        </div>
      </ng-template>
      <ng-template pTemplate>
        <p-splitter [panelSizes]="[20, 80]">
          <ng-template pTemplate>
            <div class="col flex align-items-center justify-content-center">
              Panel 3
            </div>
          </ng-template>
          <ng-template pTemplate>
            <div class="col flex align-items-center justify-content-center">
              Panel 4
            </div>
          </ng-template>
        </p-splitter>
      </ng-template>
    </p-splitter>
  </ng-template>
</p-splitter>

<!-- <div class="justify-content-center align-items-center text-center">
  <h2>Work in progress...</h2>
</div> -->

<div class="flex flex-column h-full min-h-full">
  <div class="grid nested-grid overflow-y-scroll">
    <div class="col-0">
      <div class="grid h-full min-h-full">
        <div class="col-12">
          <!--  -->
        </div>
        <div class="col-12">
          <!-- <app-summary></app-summary> -->
        </div>
      </div>
    </div>

    <div class="col-9">
      <div class="grid">
        <div class="col-6" *ngIf="false">
          <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
            <div
              class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
            >
              <div class="flex align-items-center mb-3">
                <i class="pi pi-shopping-cart text-blue-500 text-xl mr-2"></i>
                <span class="text-500 font-medium">Orders</span>
              </div>
              <span class="block text-900 font-medium mb-4 text-xl"
                >152 New</span
              >
              <div class="flex align-items-center">
                <i class="pi pi-arrow-down text-pink-500 text-xl mr-2"></i>
                <span class="text-pink-500 font-medium">-25</span>
              </div>
            </div>
            <div
              class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
            >
              <div class="flex align-items-center mb-3">
                <i class="pi pi-shopping-cart text-orange-500 text-xl mr-2"></i>
                <span class="text-500 font-medium">Revenue</span>
              </div>
              <span class="block text-900 font-medium mb-4 text-xl">$1500</span>
              <div class="flex align-items-center">
                <i class="pi pi-arrow-up text-green-500 text-xl mr-2"></i>
                <span class="text-green-500 font-medium">+15</span>
              </div>
            </div>
            <div
              class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
            >
              <div class="flex align-items-center mb-3">
                <i class="pi pi-users text-cyan-500 text-xl mr-2"></i>
                <span class="text-500 font-medium">Customers</span>
              </div>
              <span class="block text-900 font-medium mb-4 text-xl">25100</span>
              <div class="flex align-items-center">
                <i class="pi pi-arrow-up text-green-500 text-xl mr-2"></i>
                <span class="text-green-500 font-medium">+%12</span>
              </div>
            </div>
            <div class="flex-auto p-3">
              <div class="flex align-items-center mb-3">
                <i class="pi pi-users text-purple-500 text-xl mr-2"></i>
                <span class="text-500 font-medium">Comments</span>
              </div>
              <span class="block text-900 font-medium mb-4 text-xl">72</span>
              <div class="flex align-items-center">
                <i class="pi pi-arrow-up text-green-500 text-xl mr-2"></i>
                <span class="text-green-500 font-medium">+20</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6" *ngIf="false">
          <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
            <div
              class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
            >
              <div class="flex align-items-center mb-3">
                <i class="pi pi-shopping-cart text-blue-500 text-xl mr-2"></i>
                <span class="text-500 font-medium">Orders</span>
              </div>
              <span class="block text-900 font-medium mb-4 text-xl"
                >152 New</span
              >
              <div class="flex align-items-center">
                <i class="pi pi-arrow-down text-pink-500 text-xl mr-2"></i>
                <span class="text-pink-500 font-medium">-25</span>
              </div>
            </div>
            <div
              class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
            >
              <div class="flex align-items-center mb-3">
                <i class="pi pi-shopping-cart text-orange-500 text-xl mr-2"></i>
                <span class="text-500 font-medium">Revenue</span>
              </div>
              <span class="block text-900 font-medium mb-4 text-xl">$1500</span>
              <div class="flex align-items-center">
                <i class="pi pi-arrow-up text-green-500 text-xl mr-2"></i>
                <span class="text-green-500 font-medium">+15</span>
              </div>
            </div>
            <div
              class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
            >
              <div class="flex align-items-center mb-3">
                <i class="pi pi-users text-cyan-500 text-xl mr-2"></i>
                <span class="text-500 font-medium">Customers</span>
              </div>
              <span class="block text-900 font-medium mb-4 text-xl">25100</span>
              <div class="flex align-items-center">
                <i class="pi pi-arrow-up text-green-500 text-xl mr-2"></i>
                <span class="text-green-500 font-medium">+%12</span>
              </div>
            </div>
            <div class="flex-auto p-3">
              <div class="flex align-items-center mb-3">
                <i class="pi pi-users text-purple-500 text-xl mr-2"></i>
                <span class="text-500 font-medium">Comments</span>
              </div>
              <span class="block text-900 font-medium mb-4 text-xl">72</span>
              <div class="flex align-items-center">
                <i class="pi pi-arrow-up text-green-500 text-xl mr-2"></i>
                <span class="text-green-500 font-medium">+20</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <!-- alarms -->
        </div>

        <div class="col-6" *ngIf="false">
          <div class="surface-card shadow-2 p-4 h-full">
            <div class="flex align-items-center justify-content-between mb-3">
              <div class="text-900 font-medium text-xl">Team Activity</div>
              <div>
                <!-- <button
                    pButton
                    pRipple
                    icon="pi pi-ellipsis-v"
                    class="p-button-text p-button-plain p-button-rounded"
                    (click)="menu1.toggle($event)"
                  ></button> -->
                <!-- <p-menu #menu1 [popup]="true" [model]="items"></p-menu> -->
              </div>
            </div>
            <ul class="list-none p-0 m-0">
              <li
                class="py-3 border-bottom-1 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row"
              >
                <div class="flex align-items-start mr-0 lg:mr-5">
                  <img
                    src="assets/images/blocks/avatars/circle-big/avatar-f-1.png"
                    class="mr-3 w-3rem h-3rem"
                  />
                  <div>
                    <span class="text-900 font-medium block mb-2"
                      >Jane Cooper</span
                    >
                    <div class="text-700 mb-2">responded to an issue.</div>
                    <a class="text-primary cursor-pointer">
                      <i class="pi pi-github text-sm mr-2"></i>
                      <span>Issue #1185</span>
                    </a>
                  </div>
                </div>
                <span
                  class="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0"
                  >14 mins ago</span
                >
              </li>
              <li
                class="py-3 border-bottom-1 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row"
              >
                <div class="flex align-items-start mr-0 lg:mr-5">
                  <img
                    src="assets/images/blocks/avatars/circle-big/avatar-m-1.png"
                    class="mr-3 w-3rem h-3rem"
                  />
                  <div>
                    <span class="text-900 font-medium block mb-2"
                      >Robert Fox</span
                    >
                    <div class="text-700">
                      changed team size from
                      <span class="text-900 font-medium">5</span> to
                      <span class="text-900 font-medium">6</span>.
                    </div>
                  </div>
                </div>
                <span
                  class="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0"
                  >20 mins ago</span
                >
              </li>
              <li
                class="py-3 border-bottom-1 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row"
              >
                <div class="flex align-items-start mr-0 lg:mr-5">
                  <img
                    src="assets/images/blocks/avatars/circle-big/avatar-f-2.png"
                    class="mr-3 w-3rem h-3rem"
                  />
                  <div>
                    <span class="text-900 font-medium block mb-2"
                      >Kristin Watson Cooper</span
                    >
                    <div class="text-700 mb-2">
                      created a Q4 presentation to an issue.
                    </div>
                    <a class="text-primary cursor-pointer">
                      <i class="pi pi-file-pdf text-sm mr-2"></i>
                      <span>q4_presentation.pdf</span>
                    </a>
                  </div>
                </div>
                <span
                  class="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0"
                  >38 mins ago</span
                >
              </li>
              <li
                class="py-3 border-bottom-1 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row"
              >
                <div class="flex align-items-start mr-0 lg:mr-5">
                  <img
                    src="assets/images/blocks/avatars/circle-big/avatar-f-3.png"
                    class="mr-3 w-3rem h-3rem"
                  />
                  <div>
                    <span class="text-900 font-medium block mb-2"
                      >Annette Black</span
                    >
                    <div class="text-700">
                      added
                      <span class="text-900 font-medium">Nico Greenberg</span>
                      to <span class="text-primary">Watchlist Tier-1</span>.
                    </div>
                  </div>
                </div>
                <span
                  class="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0"
                  >1 day ago</span
                >
              </li>
              <li
                class="py-3 flex md:align-items-start md:justify-content-between flex-column md:flex-row"
              >
                <div class="flex align-items-start mr-0 lg:mr-5">
                  <img
                    src="assets/images/blocks/avatars/circle-big/avatar-m-2.png"
                    class="mr-3 w-3rem h-3rem"
                  />
                  <div>
                    <span class="text-900 font-medium block mb-2"
                      >Floyd Miles</span
                    >
                    <div class="text-700">
                      has refunded a blue t-shirt for
                      <span class="text-primary font-medium">$79</span>.
                    </div>
                  </div>
                </div>
                <span
                  class="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0"
                  >4 days ago</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- products -->
<div class="grid">
  <div class="col-9 md:col-7">
    <div
      class="flex flex-column md:align-items-center md:justify-content-between md:flex-row"
    >
      <div class="font-medium text-3xl text-900">Products</div>
      <div class="mt-3 md:mt-0">
        <button
          pButton
          pRipple
          label="Add"
          class="p-button-outlined mr-2"
          icon="pi pi-plus"
        ></button>
      </div>
    </div>
    <ng-container *ngFor="let category of categories">
      <div class="grid">
        <p-divider class="col-12">
          <div class="text-800 font-medium text-3xl">
            {{ category.name }}
          </div>
        </p-divider>
        <!-- products -->
        <ng-container *ngFor="let product of products">
          <div
            class="col-12 md:col-4 xl:col-4 p-3"
            *ngIf="product.external_category === category.key"
          >
            <div class="surface-card shadow-2 border-round px-5 pt-6 pb-2">
              <div class="surface-border grid">
                <div class="h-5rem align-items-center text-center">
                  <span class="text-xl text-900 font-medium mb-4 col-12">{{
                    product.name
                  }}</span>
                </div>
                <div class="h-3rem text-center col-12">
                  <span class="text-sm text-600">
                    {{ product.description }}
                  </span>
                </div>
                <div class="inline-block text-center col-12">
                  <span class="text-lg text-700 font-bold">
                    {{ product.external_product_code | uppercase }}
                  </span>
                </div>
                <!-- <p-divider class="col-12"></p-divider> -->

                <div class="flex flex-column align-items-center col-12 mt-3">
                  <div class="text-center">
                    <span class="text-2xl text-800 font-semibold">{{
                      product.price_excl
                        | currency : product.currency : "symbol-narrow"
                    }}</span>
                    <span class="text-sm text-600 mx-2">excl VAT</span>
                  </div>
                  <div class="text-center mt-1">
                    <span class="text-3xl text-green-500 font-semibold">{{
                      product.price_incl
                        | currency : product.currency : "symbol-narrow"
                    }}</span>
                    <span class="text-sm text-600 mx-2">incl VAT</span>
                  </div>
                </div>

                <span class="text-600 font-medium col-12 text-center">{{
                  product.product_type === "fixed" ? "Month-to-Month" : ""
                }}</span>

                <div class="col-6 border-right-1 border-gray-400 text-center">
                  <span class="text-blue-500 font-medium text-center text-lg"
                    ><i class="pi pi-arrow-down"> </i>
                    30
                    <!-- {{ product.speed_down_mbps }} -->
                    Mbps</span
                  >
                  <br />
                  <span class="text-600 font-medium text-center"
                    >Download
                  </span>
                </div>

                <div class="col-6 border-left-1 border-gray-400 text-center">
                  <span class="text-green-500 font-medium text-center text-lg"
                    ><i class="pi pi-arrow-up"> </i>
                    30
                    <!-- {{ product.speed_up_mbps }}  -->
                    Mbps
                  </span>
                  <br />
                  <span class="text-600 font-medium text-center">Upload </span>
                  <span></span>
                </div>
                <div class="col-12 pt-3">
                  <button
                    pButton
                    type="button"
                    label="View"
                    class="p-button-raised p-button-rounded w-full m-1"
                  ></button>
                  <button
                    pButton
                    type="button"
                    label="Edit"
                    icon="pi pi-pencil"
                    class="p-button-raised p-button-rounded w-6 p-button-warning p-button-outlined my-1"
                  ></button>
                  <button
                    pButton
                    type="button"
                    label="Remove"
                    icon="pi pi-trash"
                    class="p-button-raised p-button-rounded w-6 p-button-danger p-button-outlined my-1"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <!-- promotions -->
  <div class="col-12 md:col-5" *ngIf="false">
    <div
      class="flex flex-column md:align-items-center md:justify-content-between md:flex-row"
    >
      <div class="grid">
        <div class="col-12">
          <div class="font-medium text-3xl text-900">Promotions</div>
          <!-- <div class="mt-3 md:mt-0">
            <button
              pButton
              pRipple
              label="Add"
              class="p-button-outlined mr-2"
              icon="pi pi-plus"
            ></button>
          </div> -->
        </div>
      </div>
    </div>

    <!-- grid -->
    <div class="grid">
      <div class="col-12">
        <p-orderList
          [value]="promotions"
          [listStyle]="{ 'max-height': '30rem' }"
          header="List of Promotions"
          filterBy="name"
          filterPlaceholder="Filter by name"
          [dragdrop]="true"
        >
          <ng-template pTemplate="header">
            <span class="text-xl text-900 font-medium mb-2 text-center col-12"
              >Promotions</span
            >
          </ng-template>
          <ng-template let-promotion pTemplate="item">
            <div class="product-item text-2xl">
              <div class="image-container">
                <!-- <img
                                      src="assets/showcase/images/demo/product/{{
                                        product.image
                                      }}"
                                      [alt]="product.name"
                                      class="product-image"
                                    /> -->
              </div>
              <div class="product-list-detail">
                <h5 class="mb-2">{{ promotion.name }}</h5>

                <div class="product-category">
                  <p-chip
                    icon="pi pi-check"
                    [label]="promotion.promotion_type"
                  ></p-chip>
                </div>
                <br />
                <span>{{ promotion.dateStart }} {{ promotion.dateEnd }}</span>
              </div>
              <div class="product-list-action">
                <h6 class="mb-2">${{ promotion.price }}</h6>
              </div>
            </div>
          </ng-template>
        </p-orderList>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { AlarmsService } from '../../_services/alarms.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-network-alarms',
  templateUrl: './network-alarms.component.html',
  styleUrls: ['./network-alarms.component.scss'],
})
export class NetworkAlarmsComponent implements OnInit {
  constructor(private as: AlarmsService) {}

  groupedAlarms: { date: string; alarms: any[] }[] = [];
  groupedAlarms$: BehaviorSubject<any> = new BehaviorSubject([]);

  ngOnInit(): void {
    this.as.getAlarms().subscribe((res) => {
      this.groupedAlarms = res;
      this.groupedAlarms$.next(this.groupedAlarms);
    });
  }
}

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/_core/_services/api.service';

@Injectable({
  providedIn: 'root',
})
export class EndCustomerServicesService {
  constructor(private api: ApiService) {}

  getEndCustomerServices(queryParams?: any) {
    if (queryParams) {
      queryParams = {
        ...queryParams,
      };
    } else {
      queryParams = {
        limit: 100,
        offset: 0,
        q: '',
        filter: [],
      };
    }
    return this.api
      .getAPI('network-portal/operations/end-customer-services', queryParams)
      .pipe(map((res) => res.body.data));
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainRoutingModule } from './main-routing.module';
import { TableComponent } from './components/table/table.component';
import { HomeComponent } from './pages/home/home.component';
import { PrimeModule } from 'src/app/prime.module';

import { FormComponent } from './components/form/form.component';
import { CrmLeadsDashboardComponent } from './pages/crm-management/crm-leads-dashboard/crm-leads-dashboard.component';
import { CrmManagementComponent } from './pages/crm-management/crm-management.component';
import { CrmStatsComponent } from './pages/crm-management/crm-stats/crm-stats.component';
import { HomeViewComponent } from './pages/home-view/home-view.component';
import { CustomerDialogComponent } from './pages/portal-management/customer-dialog/customer-dialog.component';
import { CustomersComponent } from './pages/portal-management/customers/customers.component';
import { UpdateCustomerComponent } from './pages/portal-management/customers/update-customer/update-customer.component';
import { CreatePackageDialogComponent } from './pages/portal-management/packages/create-package-dialog/create-package-dialog.component';
import { PackagesComponent } from './pages/portal-management/packages/packages.component';
import { UpdatePackageDialogComponent } from './pages/portal-management/packages/update-package-dialog/update-package-dialog.component';
import { PortalManagementComponent } from './pages/portal-management/portal-management.component';
import { PortalSettingsComponent } from './pages/portal-management/portal-settings/portal-settings.component';
import { SubscriptionActionsComponent } from './pages/portal-management/subscription-actions/subscription-actions.component';
import { SubscriptionDetailsComponent } from './pages/portal-management/subscriptions/subscription-details/subscription-details.component';
import { SubscriptionsComponent } from './pages/portal-management/subscriptions/subscriptions.component';
import { TransactionsComponent } from './pages/portal-management/transactions/transactions.component';
import { UserActionsComponent } from './pages/portal-management/user-actions/user-actions.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { TenantManagementComponent } from './pages/tenant-management/tenant-management.component';
import { TenantUsersComponent } from './pages/tenant-management/tenant-users/tenant-users.component';
import { TenantsComponent } from './pages/tenant-management/tenants/tenants.component';

import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { ShareModule } from 'src/app/_share/share.module';
import { UserPreferencesComponent } from './components/user-preferences/user-preferences.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { DetailsComponent } from './components/details/details.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SummaryComponent } from './components/summary/summary.component';
import { OperationsComponent } from './pages/operations/operations.component';
import { ServicesComponent } from '../network-portal/pages/services/services.component';
import { StatisticsSummaryComponent } from './components/statistics-summary/statistics-summary.component';
import { ViewDetailsComponent } from './components/view-details/view-details.component';
import { PortalTenantsComponent } from './components/portal-tenants/portal-tenants.component';
import { SubscriptionStatisticsComponent } from './pages/portal-management/subscription-statistics/subscription-statistics.component';
import { SubscriptionsUpdateDialogComponent } from './pages/portal-management/subscriptions/subscriptions-update-dialog/subscriptions-update-dialog.component';
import { UserAccountProfileComponent } from './pages/home/user-account-profile/user-account-profile.component';
import { ConversationViewComponent } from '../support/components/conversation-view/conversation-view.component';
import { DeviceConfigurationDialogComponent } from './pages/operations/device-configuration-dialog/device-configuration-dialog.component';
import { IspOperationsComponent } from '../network-portal/pages/isp-operations/isp-operations.component';
import { CustomerCircuitsComponent } from '../network-portal/pages/customer-circuits/customer-circuits.component';
import { OrdersComponent } from '../network-portal/pages/orders/orders.component';
import { NetworkAlarmsComponent } from '../network-portal/components/network-alarms/network-alarms.component';
import { OperationsOverviewComponent } from '../network-portal/pages/operations-overview/operations-overview.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { SystemRolesComponent } from './pages/tenant-management/system-roles/system-roles.component';
import { ProductsPromotionsComponent } from '../network-portal/components/products-promotions/products-promotions.component';
import { ServicesBillingComponent } from '../network-portal/pages/services-billing/services-billing.component';
import { ServicesCustomersComponent } from '../network-portal/pages/services-customers/services-customers.component';
import { ServicesChargesComponent } from '../network-portal/pages/services-charges/services-charges.component';
import { ServicesManagementComponent } from '../network-portal/pages/services-management/services-management.component';
import { SupportCentreComponent } from '../support/pages/support/support-centre.component';

@NgModule({
  declarations: [
    TableComponent,
    HomeComponent,
    FormComponent,
    SettingsComponent,
    DashboardComponent,

    HomeViewComponent,
    PortalManagementComponent,
    PackagesComponent,
    SubscriptionsComponent,
    TransactionsComponent,
    CustomersComponent,
    PortalSettingsComponent,
    CrmManagementComponent,
    UserPreferencesComponent,
    UserProfileComponent,
    CrmLeadsDashboardComponent,
    CrmStatsComponent,

    CustomerDialogComponent,
    CreatePackageDialogComponent,
    UpdatePackageDialogComponent,
    UpdateCustomerComponent,
    SubscriptionDetailsComponent,
    UserActionsComponent,
    SubscriptionActionsComponent,

    CustomerDialogComponent,
    CreatePackageDialogComponent,
    UpdatePackageDialogComponent,
    UpdateCustomerComponent,
    SubscriptionDetailsComponent,
    TenantManagementComponent,
    TenantsComponent,
    TenantUsersComponent,
    DetailsComponent,
    SummaryComponent,
    OperationsComponent,
    IspOperationsComponent,
    ServicesComponent,
    StatisticsSummaryComponent,
    ViewDetailsComponent,
    PortalTenantsComponent,
    SubscriptionStatisticsComponent,
    SubscriptionsUpdateDialogComponent,
    UserAccountProfileComponent,
    ConversationViewComponent,
    DeviceConfigurationDialogComponent,

    CustomerCircuitsComponent,
    OrdersComponent,
    NetworkAlarmsComponent,
    OperationsOverviewComponent,
    SystemRolesComponent,
    ProductsPromotionsComponent,
    ServicesBillingComponent,
    ServicesCustomersComponent,
    ServicesChargesComponent,
    ServicesManagementComponent,
    SupportCentreComponent,
  ],
  imports: [
    CommonModule,

    MainRoutingModule,
    PrimeModule,
    HttpClientJsonpModule,
    HttpClientModule,

    ShareModule,
    FormsModule,
    GoogleMapsModule,
  ],
  exports: [
    TableComponent,
    HomeComponent,
    FormComponent,
    SettingsComponent,
    HomeViewComponent,
    OperationsComponent,
    IspOperationsComponent,
    ServicesComponent,
    PortalManagementComponent,
    PackagesComponent,
    SubscriptionsComponent,
    TransactionsComponent,
    CustomersComponent,
    PortalSettingsComponent,
    CrmManagementComponent,
    UserPreferencesComponent,
    UserProfileComponent,
    CrmLeadsDashboardComponent,
    CrmStatsComponent,
    CustomerDialogComponent,
    CreatePackageDialogComponent,
    UpdatePackageDialogComponent,
    UpdateCustomerComponent,
    SubscriptionDetailsComponent,
    UserActionsComponent,
    SubscriptionActionsComponent,
    SubscriptionsUpdateDialogComponent,

    CustomerDialogComponent,
    CreatePackageDialogComponent,
    UpdatePackageDialogComponent,
    UpdateCustomerComponent,
    SubscriptionDetailsComponent,
    TenantManagementComponent,
    TenantsComponent,
    TenantUsersComponent,
    ViewDetailsComponent,
    DetailsComponent,
    DeviceConfigurationDialogComponent,
    CustomerCircuitsComponent,
    NetworkAlarmsComponent,
    OperationsOverviewComponent,
    SystemRolesComponent,
    ProductsPromotionsComponent,
    ServicesBillingComponent,
    ServicesCustomersComponent,
    ServicesChargesComponent,
    ServicesManagementComponent,
    SupportCentreComponent,
  ],
})
export class MainModule {}

<!-- billing history -->
<div class="col-8 md:col-9">
  <div class="flex flex-column md:align-items-center md:justify-content-between md:flex-row">
    <div class="flex-auto font-medium text-3xl text-900 mb-4">Services</div>
  </div>
  <div class="flex-1 border-round shadow-2 p-3">
    <p-table #servicesTable [value]="(services$ | async) || []" sortMode="single" [scrollable]="true"
      scrollHeight="400px" groupRowsBy="representative.name" selectionMode="single" [(selection)]="selectedRow"
      (onRowSelect)="onRowSelect($event)" [tableStyle]="{ 'min-width': '60rem' }">
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of columns">{{ col.name }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="groupheader" let-item>
        <tr pRowGroupHeader>
          <td [attr.colspan]="getNumberOfColumns()">
            <span class="font-bold ml-2">{{ item.product_type }}</span>
          </td>
        </tr>
      </ng-template>
      <!-- <ng-template pTemplate="groupfooter" let-customer>
          <tr>
            <td
              [attr.colspan]="getNumberOfColumns()"
              class="text-right font-bold pr-6"
            >
              Total Customers:
            </td>
          </tr>
        </ng-template> -->
      <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
        <tr>
          <td *ngFor="let col of columns" class="white-space-nowrap">
            {{ resolveFieldData(item, col.field) }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
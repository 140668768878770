<div class="flex flex-column h-full">
  <div class="surface-card shadow-1 p-3 z-0 flex-1">
    <div class="grid nested-grid" style="max-width: 100vw">
      <div class="col-12">
        <div class="grid">
          <div class="col-12">
            <p-tabView>
              <!-- <p-tabPanel header="Overview"> </p-tabPanel> -->
              <p-tabPanel *ngFor="let tab of this.tabs" [header]="tab.name">
                <div class="grid" style="max-width: 100vw">
                  <ng-container [ngSwitch]="tab.key">
                    <ng-container *ngSwitchCase="'overview'">
                      <div class="col-12 md:col-12">
                        <div class="col-12 md:col-12">
                          <app-operations-overview></app-operations-overview>
                          <div
                            class="justify-content-center align-items-center text-center h-full min-h-full flex flex-column"
                          >
                            <!-- <h2 class="flex-1">
                              Check back soon! Work in progress
                            </h2> -->
                          </div>
                        </div>
                        <!-- <app-conversation-view></app-conversation-view> -->
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'customer-circuits'">
                      <div class="col-12 md:col-12">
                        <app-customer-circuits></app-customer-circuits>
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'customer-orders'">
                    </ng-container>
                    <ng-container *ngSwitchCase="'support'"> </ng-container>
                  </ng-container>
                </div> </p-tabPanel
            ></p-tabView>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { FilterCondition, FilterGroup } from '../../_models/filter.model';
import { QueryBuilderService } from '../../_services/query-builder.service';

@Component({
  selector: 'share-filter',
  templateUrl: './share-filter.component.html',
  styleUrls: ['./share-filter.component.scss'],
})
export class ShareFilterComponent implements OnInit {
  @Input() filterGroup: FilterGroup = {
    type: 'group',
    logic: 'and',
    conditions: [],
  };

  showQueryDialog = false;
  queryString = '';
  queryObject: any = null;

  logicOptions = [
    { label: 'AND', value: 'and' },
    { label: 'OR', value: 'or' },
  ];

  fields = [
    { label: 'Name', value: 'name' },
    { label: 'Age', value: 'age' },
    { label: 'Email', value: 'email' },
    { label: 'Status', value: 'status' },
  ];

  operators = [
    { label: 'Equals', value: 'eq' },
    { label: 'Not Equals', value: 'neq' },
    { label: 'Contains', value: 'contains' },
    { label: 'Greater Than', value: 'gt' },
    { label: 'Less Than', value: 'lt' },
  ];

  constructor(private qbs: QueryBuilderService) {}

  ngOnInit() {
    this.addCondition();
  }

  addCondition() {
    const newCondition: FilterCondition = {
      type: 'condition',
      field: '',
      operator: '',
      value: null,
    };
    this.filterGroup.conditions.push(newCondition);
  }

  removeCondition(index: number) {
    this.filterGroup.conditions.splice(index, 1);
    if (this.filterGroup.conditions.length === 0) {
      this.addCondition();
    }
  }

  addNestedGroup(index: number) {
    const nestedGroup: FilterGroup = {
      type: 'group',
      logic: 'and',
      conditions: [
        {
          type: 'condition',
          field: '',
          operator: '',
          value: null,
        },
      ],
    };
    this.filterGroup.conditions[index] = nestedGroup;
  }

  isFilterCondition(
    condition: FilterCondition | FilterGroup,
  ): condition is FilterCondition {
    return condition.type === 'condition';
  }

  isFilterGroup(
    condition: FilterCondition | FilterGroup,
  ): condition is FilterGroup {
    return condition.type === 'group';
  }

  onNestedFilterChange(filterGroup: FilterGroup) {
    // Handle nested filter changes
    this.filterGroup = { ...filterGroup };
  }

  generateQuery() {
    this.queryString = this.qbs.buildQueryString(this.filterGroup);
    this.queryObject = this.qbs.buildQueryObject(this.filterGroup);
    this.showQueryDialog = true;
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      // You might want to add a toast notification here
      console.log('Copied to clipboard');
    });
  }
}

import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ApiService } from 'src/app/_core/_services/api.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkBillingRunsService {
  constructor(private api: ApiService) {}

  getBillingRuns(queryParams?: any) {
    if (queryParams) {
      queryParams = {
        ...queryParams,
      };
    } else {
      queryParams = {
        limit: 100,
        offset: 0,
        q: '',
        filter: [],
      };
    }
    return this.api
      .getAPI('network-portal/billing/admin/billing-runs', queryParams)
      .pipe(map((res) => res.body.results));
  }

  createBillingRun(data: any) {
    return this.api.postAPI('network-portal/billing/admin/billing-runs', data);
  }

  getBillingScheduleByBillingRunId(billingRunId: number, queryParams?: any) {
    if (queryParams) {
      queryParams = {
        ...queryParams,
      };
    } else {
      queryParams = {
        limit: 100,
        offset: 0,
        q: '',
        filter: [],
      };
    }
    return this.api
      .getAPI(
        `network-portal/billing/admin/billing-runs/${billingRunId}/billing-schedule`,
        queryParams
      )
      .pipe(map((res) => res.body.results));
  }

  downloadBillingScheduleByBillingRunId(billingRunId: number) {
    return this.api.downloadFileAPI(
      `network-portal/billing/admin/billing-runs/${billingRunId}/billing-schedule/download`
    );
  }
}

<div class="text-900 font-medium text-xl mb-3">Update Device Configuration</div>

<form [formGroup]="configurationForm" (ngSubmit)="onSubmit()">
  <div class="grid formgrid p-fluid">
    <!-- SSID -->
    <div class="field mb-4 col-12 md:col-6">
      <label for="wifi_password" class="font-medium text-900">WiFi SSID</label>
      <input id="wifi_password" formControlName="wifi_ssid" pInputText />
      <div class="my-1">
        <small
          class="p-error"
          *ngIf="
            configurationForm.get('wifi_ssid')?.hasError('required') &&
            configurationForm.get('wifi_ssid')?.touched &&
            configurationForm.get('wifi_ssid')?.invalid
          "
          >SSID is required</small
        >
        <small
          class="p-error"
          *ngIf="
            configurationForm.get('wifi_ssid')?.hasError('pattern') &&
            configurationForm.get('wifi_ssid')?.touched &&
            configurationForm.get('wifi_ssid')?.invalid
          "
          >SSID must be 6 to 32 characters long and can contain letters,
          numbers, spaces, dashes, and underscores.</small
        >
        <!-- <p-message
          *ngIf="
            configurationForm.get('wifi_ssid')?.hasError('required') &&
            configurationForm.get('wifi_ssid')?.touched
          "
          severity="error"
          text="SSID is required"
          styleClass="w-full"
        ></p-message> -->
      </div>
    </div>
    <!-- PASSWORD -->
    <div class="field mb-4 col-12 md:col-6">
      <label for="wifi_password_confirm" class="font-medium text-900"
        >WiFi Password</label
      >
      <p-password
        id="wifi_password"
        formControlName="wifi_password"
        [feedback]="false"
        [showClear]="true"
        [toggleMask]="true"
      ></p-password>
      <div class="my-1">
        <small
          class="p-error"
          *ngIf="
            configurationForm.get('wifi_password')?.hasError('required') &&
            configurationForm.get('wifi_password')?.touched &&
            configurationForm.get('wifi_password')?.invalid
          "
          >Password is required</small
        >
        <small
          class="p-error"
          *ngIf="
            configurationForm.get('wifi_password')?.hasError('pattern') &&
            configurationForm.get('wifi_password')?.touched &&
            configurationForm.get('wifi_password')?.invalid
          "
          >Password must be at least 8 characters long, contain one uppercase
          letter, one lowercase letter, one number, and one special
          character.</small
        >
      </div>
    </div>
    <!-- feedback -->
    <div
      *ngIf="errorMessage !== ''"
      class="field col-12 align-items-center justify-content-center"
    >
      <p-message
        severity="error"
        [text]="errorMessage"
        styleClass="w-full"
      ></p-message>
    </div>
    <!-- SUBMIT BUTTON -->
    <div class="field col-12">
      <button
        type="submit"
        pButton
        pRipple
        [disabled]="!configurationForm.valid"
        label="Update Configuration"
        class="w-full"
      ></button>
    </div>
  </div>
</form>

import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EndCustomerCircuitsService } from 'src/app/features/main-application/_services/entities/end-customer-circuits.service';
import { EndCustomerServicesService } from '../../_services/end-customer-services.service';
import { TableMetaData } from 'src/app/_share/_models/components/table-meta-data.model';

@Component({
  selector: 'app-customer-circuits',
  templateUrl: './customer-circuits.component.html',
  styleUrls: ['./customer-circuits.component.scss'],
})
export class CustomerCircuitsComponent implements OnInit {
  columns: any[] = [
    { field: 'device_physical_status', header: 'Status' },

    { field: 'circuit_no', header: 'Circuit No' },
    { field: 'device_serial_no', header: 'Device Serial No' },

    { field: 'download_mbps', header: 'Download (Mbps)' },
    { field: 'upload_mbps', header: 'Upload (Mbps)' },

    { field: 'wifi_ssid', header: 'Wifi SSID' },
    { field: 'wifi_password', header: 'Wifi Password' },

    { field: 'customer_name', header: 'Customer Name' },
    { field: 'customer_address', header: 'Customer Address' },
    { field: 'customer_contact', header: 'Customer Contact' },
  ];

  customerServices: any[] = [];
  customerServices$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  customerServicesQuery$: any;

  selectedRow$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  metaData: TableMetaData = {
    totalRecords: 100,
    loading: false,
    rows: 100,
    first: 0,
    filter: [],
    globalFilter: '',
  };

  constructor(private ecs: EndCustomerServicesService) {}

  ngOnInit(): void {
    // const queryParams = {
    //   limit: this.metaData.rows,
    //   offset: this.metaData.first,
    //   q: this.metaData.globalFilter,
    //   filter: [],
    // };
    this.customerServicesQuery$ = this.ecs
      .getEndCustomerServices()
      .subscribe((res) => {
        console.log(res);
        this.customerServices = res;
        this.customerServices$.next(this.customerServices);
      });
  }

  onMetaChange($event: TableMetaData) {
    const queryParams = {
      limit: $event.rows,
      offset: $event.first,
      q: $event.globalFilter,
      filter: [],
    };
    console.log($event);
    this.customerServicesQuery$ = this.ecs
      .getEndCustomerServices(queryParams)
      .subscribe((res) => {
        console.log(res);
        this.customerServices = res;
        this.customerServices$.next(this.customerServices);
      });
  }

  showDetails($event: any) {
    console.log('details', $event);
    this.selectedRow$.next($event);
  }

  ngOnDestroy(): void {
    if (this.customerServicesQuery$) {
      this.customerServicesQuery$.unsubscribe();
    }
  }
}

<div class="filter-container">
    <p-dropdown [options]="logicOptions" [(ngModel)]="filterGroup.logic" optionLabel="label" optionValue="value"
        class="mb-3">
    </p-dropdown>

    <div *ngFor="let condition of filterGroup.conditions; let i = index" class="mb-3">
        <div class="filter-row p-fluid grid">
            <!-- Field Selection -->
            <div class="col-3" *ngIf="isFilterCondition(condition)">
                <p-dropdown [options]="fields" [(ngModel)]="condition.field" optionLabel="label" optionValue="value"
                    placeholder="Select Field">
                </p-dropdown>
            </div>

            <!-- Operator Selection -->
            <div class="col-3" *ngIf="isFilterCondition(condition)">
                <p-dropdown [options]="operators" [(ngModel)]="condition.operator" optionLabel="label"
                    optionValue="value" placeholder="Select Operator">
                </p-dropdown>
            </div>

            <!-- Value Input -->
            <div class="col-4" *ngIf="isFilterCondition(condition)">
                <input pInputText type="text" [(ngModel)]="condition.value" placeholder="Enter value">
            </div>

            <!-- Action Buttons -->
            <div class="col-2 flex gap-2">
                <p-button icon="pi pi-trash" (onClick)="removeCondition(i)" severity="danger"></p-button>
                <p-button icon="pi pi-plus" (onClick)="addNestedGroup(i)" severity="secondary"
                    *ngIf="isFilterCondition(condition)">
                </p-button>
            </div>
        </div>

        <!-- Nested Group -->
        <div *ngIf="isFilterGroup(condition)" class="nested-group ml-4 mt-2">
            <share-filter [filterGroup]="condition" (filterChange)="onNestedFilterChange(condition)">
            </share-filter>
        </div>
    </div>

    <!-- Add Condition Button -->
    <p-button label="Add Condition" icon="pi pi-plus" (onClick)="addCondition()" class="mt-3">
    </p-button>

    <div class="mt-3 flex gap-2">
        <p-button label="Add Condition" icon="pi pi-plus" (onClick)="addCondition()">
        </p-button>

        <p-button label="Generate Query" icon="pi pi-code" (onClick)="generateQuery()" severity="secondary">
        </p-button>
    </div>
</div>

<!-- Query Preview Dialog -->
<p-dialog header="Generated Query" [(visible)]="showQueryDialog" [style]="{width: '70vw'}" [modal]="true">
    <div class="query-preview">
        <h3>SQL-like Query:</h3>
        <p-card>
            <pre>{{ queryString }}</pre>
        </p-card>

        <h3 class="mt-4">Query Object:</h3>
        <p-card>
            <pre>{{ queryObject | json }}</pre>
        </p-card>
    </div>

    <p-divider></p-divider>

    <div class="flex justify-content-end gap-2">
        <p-button label="Copy Query String" icon="pi pi-copy" (onClick)="copyToClipboard(queryString)"
            severity="secondary">
        </p-button>
        <p-button label="Copy Query Object" icon="pi pi-copy" (onClick)="copyToClipboard(queryObject)"
            severity="secondary">
        </p-button>
    </div>
</p-dialog>
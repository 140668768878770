import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ApiService } from 'src/app/_core/_services/api.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkCustomersService {
  constructor(private api: ApiService) {}

  getCustomers() {
    return this.api.getAPI('network-portal/billing/customers').pipe(
      map((res: any) => {
        return res.body.results;
      })
    );
  }
}

<div class="flex flex-column h-full">
  <div class="surface-card shadow-1 p-3 z-0 flex-1">
    <div class="grid nested-grid" style="max-width: 100vw">
      <div class="col-12">
        <div class="grid">
          <div class="col-12">
            <p-tabView [activeIndex]="activeTab" (onChange)="changeTab($event)">
              <!-- fixme: this is excessive for loop and switch to select the tabs -->
              <p-tabPanel *ngFor="let tab of this.tabs" [header]="tab.name">
                <div [ngSwitch]="tab.name">
                  <div
                    class="grid grid-nogutter"
                    style="max-width: 100vw"
                    *ngSwitchCase="'Devices'"
                  >
                    <!-- current action buttons -->
                    <div class="col-12 md:col-12">
                      <div class="">
                        <div class="surface-card p-2 shadow-2">
                          <span class="p-buttonset">
                            <button
                              pButton
                              class="p-button-info"
                              icon="pi pi-plus"
                              iconPos="left"
                              label="Add new item to {{ tab.name }}"
                              (click)="showAdd(tab)"
                            ></button>
                          </span>
                        </div>
                      </div>
                    </div>
                    <!-- statistics -->
                    <div class="col-12" *ngIf="true">
                      <app-statistics-summary
                        [summaryData]="devicesSummaryStatistics"
                        [summaryTopic]="'device'"
                      ></app-statistics-summary>
                    </div>
                    <div class="col-12 md:col-3" *ngIf="message[tab?.key]">
                      <div class="surface-card shadow-1 p-1 lg:p-4">
                        <h2>{{ tab.name | removeS }} Information</h2>
                        <!-- <p-divider></p-divider> -->

                        <app-details
                          [rowData]="message[tab?.key]"
                          [selectedTab]="tab.name"
                        ></app-details>

                        <!-- chart -->
                        <div class="col-12" *ngIf="false">
                          <div class="shadow-1 border-custom p-1 lg:p-4">
                            <div class="card">
                              <p-chart
                                type="bar"
                                [data]="basicData"
                                [options]="horizontalOptions"
                              ></p-chart>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- share table -->
                    <div
                      [ngClass]="{
                        'col-12 md:col-9': message[tab?.key],
                        'col-12 md:col-12': !message[tab?.key]
                      }"
                    >
                      <div class="surface-card shadow-1 p-1 lg:p-4">
                        <share-table
                          [data]="tabDataSources[tab.key]"
                          [columns]="schemaDefinitions[tab.key]?.columns"
                          [(metaData)]="
                            entityService.metaDataEntityMap[tab.key]
                          "
                          (metaDataChange)="
                            entityService.refreshEntities(tab.key)
                          "
                          (selectedRowCMObservable$)="
                            showContextAction($event, tab)
                          "
                          (selectedRowObservable$)="showDetails($event, tab)"
                          (selectedRelatedEntityObservable$)="
                            showRelatedEntity($event)
                          "
                        >
                        </share-table>
                      </div>
                    </div>
                  </div>
                  <!-- 
                                  <div
                                      class="grid"
                                      style="max-width: 100vw"
                                      *ngSwitchCase="'ISPs'"
                                  >
                                      <div class="col-4">
                                          <div class="surface-card shadow-1 border-round p-4">
                                              <div class="">
                                                  <h2>Selected {{ tab.name | removeS }}</h2>
                                                  <p-divider></p-divider>
                                                  <div *ngIf="!message[tab?.key]">
                                                      <h4>No ISP Selected</h4>
                                                  </div>
                                                  <div>
                                                      <app-details
                                                          [rowData]="message[tab?.key]"
                                                          [selectedTab]="tab.name"
                                                      ></app-details>
                                                      
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="col-6">
                                              <button
                                                  pButton
                                                  class="p-button-info"
                                                  icon="pi pi-plus"
                                                  iconPos="left"
                                                  label="Add new item to {{ tab.name }}"
                                                  (click)="showAdd(tab)"
                                              ></button>
                                          </div>
                                          <div class="col-6">
                                              &nbsp;
                                              <button
                                                  pButton
                                                  class="p-button-danger"
                                                  icon="pi pi-trash"
                                                  iconPos="left"
                                                  label="Delete all {{ tab.name }}"
                                                  (click)="showDeleteAll(tab)"
                                              ></button>
                                          </div>
                                      </div>
                                      <div class="col-8">
                                          <div class="col-12">
                                              <app-table
                                                  (messageEvent)="recieveMessage($event, tab)"
                                                  [data]="tabDataSources[tab.key]"
                                                  [activeTab]="tab"
                                                  [columns]="schemaDefinitions[tab.key]?.columns"
                                                  (selectedRowCMObservable$)="showEdit($event, tab)"
                                                  [pageAmount]="
                                                      this.userPreferencesService.userPreferences.pageAmount
                                                  "
                                              ></app-table>
                                          </div>
                                          <div class="col-12">
                                              <div
                                                  class="surface-card shadow-1 border-round p-4 h-full"
                                              >
                                                  <app-details
                                                      [rowData]="message[tab?.key]"
                                                  ></app-details>
                                              </div>
                                          </div>
                                      </div>
                                  </div> -->
                  <!-- <div
                    class="grid"
                    style="max-width: 100vw"
                    *ngSwitchCase="'End Customer Circuits'"
                  >
                    <div class="col-3">
                      <div class="grid">
                        <div
                          *ngIf="message[tab?.key]"
                          class="col-12 surface-card shadow-1 border-round p-4 mt-2"
                        >
                          <h2>{{ tab.name | removeS }} Information</h2>
                          <p-divider></p-divider>
                          <div class="">
                            <app-details
                              [rowData]="message[tab?.key]"
                              [selectedTab]="tab.name"
                            ></app-details>
                          </div>
                        </div>
                        <div class="col-12 surface-card shadow-1 mt-2">
                          <div class="text-center mb-2">
                            <span class="text-2xl font-bold"
                              >Warnings & Alerts</span
                            >
                          </div>
                          <div
                            class="flex justify-content-center col-12 surface-card shadow-1 border-1 border-round border-red-500 p-4"
                          >
                            <i
                              class="pi pi-exclamation-triangle text-red-500 text-xl mr-2"
                            ></i>
                            <div class="text-center text-red-500">
                              Error Example Device
                            </div>
                          </div>
                          <div
                            class="flex justify-content-center col-12 surface-card shadow-1 border-1 border-round border-orange-400 mt-2 p-4"
                          >
                            <i
                              class="pi pi-exclamation-circle text-orange-400 text-xl mr-2"
                            ></i>
                            <div class="text-center text-orange-400">
                              Warning Example Device
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <button
                            pButton
                            class="p-button-info"
                            icon="pi pi-plus"
                            iconPos="left"
                            label="Add new item to {{ tab.name }}"
                            (click)="showAdd(tab)"
                          ></button>
                        </div>
                        <div class="col-6">
                          &nbsp;
                          <button
                            pButton
                            class="p-button-danger"
                            icon="pi pi-trash"
                            iconPos="left"
                            label="Delete all {{ tab.name }}"
                            (click)="showDeleteAll(tab)"
                          ></button>
                        </div>
                      </div>
                    </div>
                    <div class="col-9">
                      <div class="surface-card shadow-1 border-round p-4">
                        <share-table
                          [data]="tabDataSources[tab.key]"
                          [columns]="schemaDefinitions[tab.key]?.columns"
                          [(metaData)]="
                            entityService.metaDataEntityMap[tab.key]
                          "
                          (metaDataChange)="
                            entityService.refreshEntities(tab.key)
                          "
                          (selectedRowCMObservable$)="
                            showContextAction($event, tab)
                          "
                          (selectedRowObservable$)="showDetails($event, tab)"
                          (selectedRelatedEntityObservable$)="
                            showRelatedEntity($event)
                          "
                        >
                        </share-table>
                      </div>
                    </div>
                  </div> -->

                  <div
                    *ngSwitchDefault
                    class="grid grid-nogutter"
                    style="max-width: 100vw"
                  >
                    <!-- current action buttons -->
                    <div class="col-12 md:col-12">
                      <div class="">
                        <div class="surface-card p-2 shadow-2">
                          <span class="p-buttonset">
                            <button
                              pButton
                              class="p-button-info"
                              icon="pi pi-plus"
                              iconPos="left"
                              label="Add new item to {{ tab.name }}"
                              (click)="showAdd(tab)"
                            ></button>
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- share table -->
                    <div class="col-12 md:col-12 py-2">
                      <div class="surface-card shadow-1 p-3 lg:p-4">
                        <share-table
                          [data]="tabDataSources[tab.key]"
                          [columns]="schemaDefinitions[tab.key]?.columns"
                          [(metaData)]="
                            entityService.metaDataEntityMap[tab.key]
                          "
                          (metaDataChange)="
                            entityService.refreshEntities(tab.key)
                          "
                          (selectedRowCMObservable$)="
                            showContextAction($event, tab)
                          "
                          (selectedRowObservable$)="showDetails($event, tab)"
                          (selectedRelatedEntityObservable$)="
                            showRelatedEntity($event)
                          "
                        >
                        </share-table>
                      </div>
                    </div>
                  </div>
                </div>
              </p-tabPanel>
            </p-tabView>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-confirmDialog
  header="Confirmation of Bulk Delete"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>

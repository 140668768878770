<div class="surface-card shadow-2 p-4">
  <div class="flex align-items-center justify-content-between mb-4">
    <div class="text-900 font-medium text-xl">Network Alerts</div>
    <div><button pButton icon="pi pi-refresh"></button></div>
  </div>
  <ng-container *ngIf="groupedAlarms$ | async as groupedAlarms; else loading">
    <ng-container
      *ngIf="groupedAlarms && groupedAlarms.length > 0; else loading"
    >
      <ng-container *ngFor="let group of groupedAlarms">
        <span class="block text-600 font-medium mb-3 text-2xl">{{
          group.date | date : "fullDate"
        }}</span>
        <ul class="p-0 mx-0 mt-0 mb-4 list-none">
          <li
            *ngFor="let alarm of group.alarms"
            class="flex align-items-center py-2 border-bottom-1 surface-border"
          >
            <div
              class="w-2rem h-2rem flex align-items-center justify-content-center border-circle mr-3 flex-shrink-0"
              [ngClass]="{
                'bg-red-100': alarm.alarm_type === 'major',
                'bg-orange-100': alarm.alarm_type === 'minor'
              }"
            >
              <i
                class="pi text-xl"
                [ngClass]="{
                  'text-red-500 pi-times-circle': alarm.alarm_type === 'major',
                  'text-orange-500 pi-exclamation-circle':
                    alarm.alarm_type === 'minor'
                }"
              ></i>
            </div>
            <span class="text-900 line-height-3 font-medium"
              >{{ alarm.circuit_no }}
              <span class="text-700 font-normal"
                >has the following alarm
                <span
                  class="font-medium"
                  [ngClass]="{
                    'text-red-600': alarm.alarm_type === 'major',
                    'text-orange-600': alarm.alarm_type === 'minor'
                  }"
                  >{{ alarm.cause }}</span
                ></span
              >
            </span>
            <span class="text-600 ml-auto">{{
              alarm.timestamp | dateAsAgo
            }}</span>
          </li>
        </ul>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #loading>
    <div class="flex align-items-center justify-content-center"></div>
    <p-skeleton height="36px" width="240px"></p-skeleton>
    <p-divider></p-divider>
    <p-skeleton height="24px"></p-skeleton>
    <p-divider></p-divider>
    <p-skeleton height="24px"></p-skeleton>
    <p-divider></p-divider>
    <p-skeleton height="24px"></p-skeleton>
    <p-divider></p-divider>
    <p-skeleton height="24px"></p-skeleton>
    <p-divider></p-divider>
    <p-skeleton height="24px"></p-skeleton>
    <p-divider></p-divider>
    <p-skeleton height="24px"></p-skeleton>
    <p-divider></p-divider>
    <p-skeleton height="24px"></p-skeleton>
    <p-divider></p-divider>
    <p-skeleton height="24px"></p-skeleton>
    <p-divider></p-divider>
    <p-skeleton height="24px"></p-skeleton>
  </ng-template>
</div>

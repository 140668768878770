<div class="surface-card shadow-2 p-4">
  <div class="flex align-items-center justify-content-between mb-4">
    <span class="text-xl font-medium text-900">Orders Overview</span>
    <div class="ml-auto"></div>
  </div>
  <div class="surface-border border-1 border-round p-3 mb-4">
    <span class="text-900 font-medium text-3xl"
      >{{ (confirmed / total) * 100 | number : "1.0-0" }}%
      <span class="text-600">({{ confirmed }}/{{ total }})</span></span
    >
    <ul class="mt-3 list-none p-0 mx-0 flex">
      <li
        *ngFor="let summary of orderSummary; let first = first; let last = last"
        [ngClass]="{ 'border-round-left': first, 'border-round-right': last }"
        class="bg-{{ summary.color }}-500"
        style="height: 1rem"
        [style.flex]="
          '0 0 ' + ((summary.value / total) * 100 | number : '1.0-0') + '%'
        "
      ></li>
    </ul>
  </div>
  <ul class="mt-4 list-none p-0 mx-0">
    <li
      *ngFor="let summary of orderSummary"
      class="flex align-items-center pb-3"
    >
      <span
        style="width: 1rem; height: 1rem"
        class="border-round bg-{{ summary.color }}-500 mr-3 flex-shrink-0"
      ></span>
      <span class="text-xl font-medium text-90">{{ summary.title }}</span>
      <span class="text-600 text-xl font-medium ml-auto">{{
        summary.value
      }}</span>
    </li>
  </ul>
</div>

<!-- <div class="flex flex-column h-full">
    <div class="surface-card shadow-1 p-3 z-0 flex-1">
        <div class="grid nested-grid" style="max-width: 100vw">
            <div class="col-12">


                <p-tabView>
                    <p-tabPanel> -->
<!-- todo: improve layout to have tabbed view on the support page as well -->
<app-conversation-view></app-conversation-view>
<!-- </p-tabPanel>
                </p-tabView>
            </div>
        </div>
    </div>

</div> -->
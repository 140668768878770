import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DevicesService } from 'src/app/features/main-application/_services/entities/devices.service';
import { EquipmentTypesService } from 'src/app/features/main-application/_services/entities/equipment-types.service';
import { DeviceConfigurationDialogComponent } from '../../pages/operations/device-configuration-dialog/device-configuration-dialog.component';
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnChanges, OnInit {
  @Input() rowData: any;
  @Input() selectedTab: any;
  @Input() idField: string = 'id';
  dataList: Array<{ key: string; value: any }> = [];
  trafficData: any;
  signalData: any;
  basicOptions: any;
  circuitDevice: any;
  equipmentTypes: any;
  ref: DynamicDialogRef | undefined;

  constructor(
    public dialogService: DialogService,

    private devicesService: DevicesService,
    private equipmentTypesService: EquipmentTypesService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['rowData']) {
      this.dataList = this.transformToReadableList(this.rowData);
      this.trafficData = this.generateFakeTrafficData(new Date(), 30);
      this.signalData = this.generateFakeSignalData(new Date(), 30);
    }

    this.devicesService
      .getDeviceById(this.rowData[this.idField])
      .subscribe((res) => {
        console.log(res);
        this.circuitDevice = res.body;

        this.equipmentTypesService
          .getEquipmentTypeById(this.circuitDevice.equipment_type_id)
          .subscribe((res) => {
            console.log(res);
            this.equipmentTypes = res.body;
          });
      });
  }

  ngOnInit(): void {
    this.trafficData = this.generateFakeTrafficData(new Date(), 30);
    this.signalData = this.generateFakeSignalData(new Date(), 30);
  }

  showDeviceConfiguration() {
    this.ref = this.dialogService.open(DeviceConfigurationDialogComponent, {
      data: {
        circuit_no: this.circuitDevice.end_customer_circuit.circuit_no,
        device_serial_no: this.circuitDevice.serial_no,
        wifi_ssid: this.circuitDevice.end_customer_circuit.wifi_ssid,
        wifi_password:
          this.circuitDevice.end_customer_circuit.wifi_ssid_password,
      },
      width: '60%',
      height: '40%',
      closeOnEscape: true,
    });
  }

  getPhysicalStatusSeverity(physical_status: string) {
    if (physical_status === 'active') {
      return 'success';
    } else if (physical_status === 'inactive') {
      return 'danger';
    } else if (physical_status === 'los') {
      return 'warning';
    } else {
      return 'warning';
    }
  }

  getPhysicalStatusIcon(physical_status: string) {
    if (physical_status === 'active') {
      return 'pi pi-globe';
    } else if (physical_status === 'inactive' || physical_status === '') {
      return 'pi pi-ban';
    } else if (physical_status === 'los') {
      return 'pi pi-sort-alt-slash';
    } else {
      return 'pi pi-question';
    }
  }

  click() {
    console.log(this.rowData);
  }

  transformToReadableList(data: any): Array<{ key: string; value: any }> {
    const list = [];
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (typeof data[key] === 'object' && data[key] !== null) {
          for (const subKey in data[key]) {
            if (data[key].hasOwnProperty(subKey)) {
              list.push({ key: `${key}.${subKey}`, value: data[key][subKey] });
            }
          }
        } else {
          list.push({ key, value: data[key] });
        }
      }
    }
    return list;
  }

  generateFakeTrafficData(startDate: Date, days: number = 30) {
    let labels = [];
    let downloadData = [];
    let uploadData = [];
    let currentDate = new Date(startDate);

    for (let i = 0; i < days; i++) {
      // Format the date as YYYY-MM-DD
      let dateString = currentDate.toISOString().split('T')[0];
      labels.push(dateString);

      // Generate random data for download and upload in Mbps
      let downloadSpeed = Math.random() * (60 - 40) + 40; // Download speed between 20 and 100 Mbps
      let uploadSpeed = Math.random() * (30 - 20) + 20; // Upload speed between 5 and 50 Mbps

      downloadData.push(downloadSpeed.toFixed(2));
      uploadData.push(uploadSpeed.toFixed(2));

      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return {
      labels: labels,
      datasets: [
        {
          label: 'Download (daily average)',
          data: downloadData,
          fill: false,
          borderColor: '#42A5F5',
          tension: 0.1,
        },
        {
          label: 'Upload (daily average)',
          data: uploadData,
          fill: false,
          borderColor: '#FFA726',
          tension: 0.1,
        },
      ],
    };
  }

  generateFakeSignalData(startDate: Date, days: number = 30) {
    let labels = [];
    let signalData = [];
    let currentDate = new Date(startDate);

    for (let i = 0; i < days; i++) {
      // Format the date as YYYY-MM-DD
      let dateString = currentDate.toISOString().split('T')[0];
      labels.push(dateString);

      // Generate random signal data in dBm between -25 and -20
      let signalStrength = Math.random() * (-20 - -25) + -25;

      signalData.push(signalStrength.toFixed(2));

      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return {
      labels: labels,
      datasets: [
        {
          label: 'Signal strength (daily average)',
          data: signalData,
          fill: false,
          borderColor: '#42A5F5',
          tension: 0.1,
        },
      ],
    };
  }
}

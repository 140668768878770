<div
  class="surface-card p-4 shadow-3 xl:col-4 col-12 xl:col-offset-4 flex-grow-1 my-3"
>
  <div class="text-center mb-5">
    <!-- <img src="assets/images/blocks/logos/hyper.svg" alt="Image" height="50" class="mb-3"> -->
    <div class="text-900 text-3xl font-medium mb-3">Welcome!</div>
    <!-- <span class="text-600 font-medium line-height-3"
      >Don't have an account?</span
    >
    <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
      >Create today!</a
    > -->
  </div>

  <div>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="formgrid">
        <div class="field">
          <label for="username" class="block text-900 font-medium mb-2"
            >Username</label
          >
          <input
            type="text"
            formControlName="username"
            class="form-control"
            pInputText
            class="w-full"
          />
          <div
            *ngIf="
              loginForm.get('username').invalid &&
              loginForm.get('username').touched &&
              loginForm.get('username').dirty &&
              loginForm.get('username').hasError('required')
            "
            class="my-1"
          >
            <small class="p-error" id="username-help"
              ><strong>Username</strong> is required</small
            >
          </div>
        </div>

        <div class="field">
          <label for="password" class="block text-900 font-medium mb-2"
            >Password</label
          >
          <input
            type="password"
            formControlName="password"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
            pInputText
            class="w-full"
          />
          <div
            *ngIf="
              loginForm.get('password').invalid &&
              loginForm.get('password').touched &&
              loginForm.get('password').dirty &&
              loginForm.get('password').hasError('required')
            "
            class="my-1"
          >
            <small class="p-error" id="password-help"
              ><strong>Password</strong> is required</small
            >
          </div>
        </div>

        <div class="flex align-items-center justify-content-between mb-4"></div>
        <button
          pButton
          pRipple
          [disabled]="loading"
          label="Sign In"
          class="w-full mb-3"
          icon="pi pi-user"
        >
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
        </button>

        <div *ngIf="error" class="alert alert-danger mt-1 mb-3">
          <p-message
            severity="error"
            [text]="error"
            styleClass="w-full"
          ></p-message>
        </div>

        <div class="flex align-items-center justify-content-between mb-3">
          <div class="flex align-items-center">
            <p-checkbox
              id="rememberme3"
              [binary]="true"
              [value]="true"
              [disabled]="true"
              styleClass="mr-2"
            ></p-checkbox>
            <label for="rememberme3">Remember me</label>
          </div>
          <a
            class="font-medium p-1 no-underline text-blue-500 text-right cursor-pointer align-items-center"
            [routerLink]="['/forgot-password']"
            >Forgot password?</a
          >
        </div>

        <p-divider align="center" styleClass="my-3">
          <span class="text-600 font-normal text-sm">OR (coming soon)</span>
        </p-divider>

        <div class="flex justify-content-between mb-3">
          <!-- <button
          pRipple
          class="mr-2 w-6 font-medium border-1 surface-border surface-100 py-3 px-2 p-component hover:surface-200 active:surface-300 text-900 cursor-pointer transition-colors transition-duration-150 inline-flex align-items-center justify-content-center"
        >
          <i class="pi pi-facebook text-indigo-500 mr-2"></i>
          <span>Sign in With Facebook</span>
        </button> -->
          <button
            disabled
            class="ml-2 w-12 font-medium border-1 surface-border surface-100 py-3 px-2 p-component hover:surface-200 text-900 transition-colors transition-duration-150 inline-flex align-items-center justify-content-center"
          >
            <i class="pi pi-google text-red-500 mr-2"></i>
            <span>Sign in With Google</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PortalProducts } from 'src/app/_share/_models/portal/interfaces';
import { BillingService } from '../../../main-application/_services/billing.service';
import { SitesService } from '../../../main-application/_services/entities/sites.service';

declare const google: any;

@Component({
  selector: 'app-isp-operations',
  templateUrl: './isp-operations.component.html',
  styleUrls: ['./isp-operations.component.scss'],
})
export class IspOperationsComponent implements OnInit {
  tabs = [
    { key: 'overview', name: 'Overview' },
    { key: 'customer-circuits', name: 'Customer Circuits' },
    // { key: 'customer-orders', name: 'Customer Orders' },
    // { key: 'support', name: 'Support' },
  ];

  areas = [
    { name: 'Windhoek', key: 'windhoek', lat: -22.5609, lng: 17.0658 },
    { name: 'Swakopmund', key: 'swakopmund', lat: -22.6787, lng: 14.535 },
    { name: 'Walvis Bay', key: 'walvis-bay', lat: -22.957019, lng: 14.50561 },
  ];

  selectedCustomer: any;
  selectedArea: any = {
    name: 'Windhoek',
    key: 'windhoek',
    lat: -22.5609,
    lng: 17.0658,
  };

  customers: any[] = [];
  customers$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    this.customers
  );

  mapOptions: any = {};

  overlays: any[] = [];

  orders = [
    {
      id: 'ORD001',
      status: 'Pending',
      dateCreated: new Date('2024-09-15'),
      dateModified: new Date('2024-09-16'),
    },
    {
      id: 'ORD002',
      status: 'Completed',
      dateCreated: new Date('2024-09-14'),
      dateModified: new Date('2024-09-15'),
    },
    // Add more mock data as needed
  ];

  products: PortalProducts[] = [
    {
      product_type: 'fixed',
      product_category: 'ftth',

      name: 'FTTH 30/15 - Assymmetrical',
      description: '30Mbps : 15Mbps FTTH Assymmetrical',
      recurring_price_excl: 600,
      recurring_price_incl: 600,
      recurring_description: 'Monthly Fee',
      speed_down_mbps: 30,
      speed_up_mbps: 15,
      currency: 'NAD',
      onceoff_price_excl: 1500,
      onceoff_price_incl: 1500,
      onceoff_description: 'Installation Fee',
      renewal_type: 'recurring',
      duration_days: 30,
    },
    {
      product_type: 'fixed',
      product_category: 'ftth',
      name: 'FTTH 60/30 - Assymmetrical',
      description: '50Mbps : 25Mbps FTTH Assymmetrical',
      recurring_price_excl: 550,
      recurring_price_incl: 550,
      recurring_description: 'Monthly Fee',
      speed_down_mbps: 60,
      speed_up_mbps: 30,
      currency: 'NAD',
      onceoff_price_excl: 1500,
      onceoff_price_incl: 1500,
      onceoff_description: 'Installation Fee',
      renewal_type: 'recurring', //month-to-month
      duration_days: 30,
    },
    {
      product_type: 'fixed',
      product_category: 'ftth',
      name: 'FTTH 100/50 - Assymmetrical',
      description: '100Mbps : 50Mbps FTTH Assymmetrical',
      recurring_price_excl: 880,
      recurring_price_incl: 880,
      recurring_description: 'Monthly Fee',
      speed_down_mbps: 100,
      speed_up_mbps: 50,
      currency: 'NAD',
      onceoff_price_excl: 1500,
      onceoff_price_incl: 1500,
      onceoff_description: 'Installation Fee',
      renewal_type: 'recurring',
      duration_days: 30,
    },
    {
      product_type: 'fixed',
      product_category: 'ftth',
      name: 'FTTH 200/100 - Assymmetrical',
      description: '100Mbps : 50Mbps FTTH Assymmetrical',
      recurring_price_excl: 880,
      recurring_price_incl: 880,
      recurring_description: 'Monthly Fee',
      speed_down_mbps: 100,
      speed_up_mbps: 50,
      currency: 'NAD',
      onceoff_price_excl: 1500,
      onceoff_price_incl: 1500,
      onceoff_description: 'Installation Fee',
      renewal_type: 'recurring',
      duration_days: 30,
    },
    {
      product_type: 'fixed',
      product_category: 'fttb',
      name: 'FTTB 30/30 - Symmetrical',
      description: '30Mbps : 30Mbps FTTH Symmetrical',
      recurring_price_excl: 780,
      recurring_price_incl: 780,
      recurring_description: 'Monthly Fee',
      speed_down_mbps: 100,
      speed_up_mbps: 50,
      currency: 'NAD',
      onceoff_price_excl: 1500,
      onceoff_price_incl: 1500,
      onceoff_description: 'Installation Fee',
      renewal_type: 'recurring',
      duration_days: 30,
    },
    {
      product_type: 'fixed',
      product_category: 'fttb',
      name: 'FTTB 50/50 - Symmetrical',
      description: '50Mbps : 50Mbps FTTH Symmetrical',
      recurring_price_excl: 780,
      recurring_price_incl: 780,
      recurring_description: 'Monthly Fee',
      speed_down_mbps: 60,
      speed_up_mbps: 60,
      currency: 'NAD',
      onceoff_price_excl: 1500,
      onceoff_price_incl: 1500,
      onceoff_description: 'Installation Fee',
      renewal_type: 'recurring',
      duration_days: 30,
    },
    {
      product_type: 'fixed',
      product_category: 'fttb',
      name: 'FTTB 100/100 - Symmetrical',
      description: '100Mbps : 100Mbps FTTH Symmetrical',
      recurring_price_excl: 780,
      recurring_price_incl: 780,
      recurring_description: 'Monthly Fee',
      speed_down_mbps: 100,
      speed_up_mbps: 100,
      currency: 'NAD',
      onceoff_price_excl: 1500,
      onceoff_price_incl: 1500,
      onceoff_description: 'Installation Fee',
      renewal_type: 'recurring',
      duration_days: 30,
    },
    {
      product_type: 'fixed',
      product_category: 'fttb',
      name: 'FTTB 200/200 - Symmetrical',
      description: '200Mbps : 200Mbps FTTH Symmetrical',
      recurring_price_excl: 780,
      recurring_price_incl: 780,
      recurring_description: 'Monthly Fee',
      speed_down_mbps: 200,
      speed_up_mbps: 200,
      currency: 'NAD',
      onceoff_price_excl: 1500,
      onceoff_price_incl: 1500,
      onceoff_description: 'Installation Fee',
      renewal_type: 'recurring',
      duration_days: 30,
    },
  ];

  categories = [
    {
      name: 'FTTH',
      key: 'ftth',
    },
    {
      name: 'FTTB',
      key: 'fttb',
    },
  ];

  promotions = [
    {
      name: 'Free Promotional Installation - WVB',
      dateStart: '2024-01-01',
      dateEnd: '2024-12-31',
      description: 'Free installation on all FTTH products - WVB',
      area: 'Windhoek',
      promotion_type: 'installation',
    },
    {
      name: 'Free Promotional Installation - WHK',
      dateStart: '2024-01-01',
      dateEnd: '2024-12-31',
      description: 'Free installation on all FTTH products - WVB',
      area: 'Windhoek',
      promotion_type: 'installation',
    },
    {
      name: 'Free Promotional Installation - WVB',
      dateStart: '2024-01-01',
      dateEnd: '2024-12-31',
      description: 'Free installation on all FTTH products - WVB',
      area: 'Windhoek',
      promotion_type: 'installation',
    },
    {
      name: 'Free Promotional Installation - WVB',
      dateStart: '2024-01-01',
      dateEnd: '2024-12-31',
      description: 'Free installation on all FTTH products - WVB',
      area: 'Windhoek',
      promotion_type: 'installation',
    },
  ];

  constructor(private bs: BillingService, private ss: SitesService) {}

  ngOnInit(): void {
    // this.bs.getCustomers().subscribe((customers) => {
    //   this.customers = customers;
    //   this.customers$.next(customers);
    // });
    // this.mapOptions = {
    //   disableDefaultUI: true,
    //   zoomControl: true,
    //   controlSize: 16,
    //   center: { lat: -22.957019, lng: 14.50561 },
    //   zoom: 12,
    // };
    // this.ss.getSites().subscribe((sites) => {
    //   console.log('sites', sites);
    //   sites.data.map((site: any) => {
    //     this.overlays.push(
    //       new google.maps.Marker({
    //         position: {
    //           lat: site.latitude,
    //           lng: site.longitude,
    //         },
    //         // label: site.name,
    //       })
    //     );
    //   });
    // });
    // this.overlays.push(
    //   new google.maps.KmlLayer(
    //     'assets/network-portal/demshi-coverage-20241011.kmz',
    //     {
    //       suppressInfoWindows: true,
    //       preserveViewport: true,
    //     }
    //   )
    // );
    // pull kml from assets/network-portal/demshi.kml
  }

  onAreaChange(event: any, map: any) {
    console.log('map event', event);
    console.log('map selected', map);

    map.setCenter({
      lat: event.value.lat,
      lng: event.value.lng,
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { IspsService } from 'src/app/features/main-application/_services/entities/isps.service';
import { RegionsService } from 'src/app/features/main-application/_services/entities/regions.service';
import { VlansService } from 'src/app/features/main-application/_services/entities/vlans.service';
import { VnosService } from 'src/app/features/main-application/_services/entities/vnos.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormComponent,
  JsonFormData,
} from 'src/app/features/main-application/components/form/form.component';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { ProductsService } from 'src/app/features/main-application/_services/entities/products.service';
import { ContactsService } from 'src/app/features/main-application/_services/entities/contacts.service';
import { EndCustomersService } from 'src/app/features/main-application/_services/entities/end-customers.service';
import { EquipmentTypesService } from 'src/app/features/main-application/_services/entities/equipment-types.service';
import { SitesService } from 'src/app/features/main-application/_services/entities/sites.service';
import { DevicesService } from 'src/app/features/main-application/_services/entities/devices.service';

import { EntityService } from 'src/app/features/main-application/_services/entity.service';
import { EndCustomerCircuitsService } from 'src/app/features/main-application/_services/entities/end-customer-circuits.service';
import { TocsService } from 'src/app/features/main-application/_services/entities/tocs.service';

import { TenantsService } from 'src/app/_share/_services/tenants.service';
import { RelationshipService } from '../../_services/relationship.service';
import { UserPreferencesService } from 'src/app/_share/_services/user-preferences.service';

import { StatisticsService } from '../../_services/statistics.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ViewDetailsComponent } from '../../components/view-details/view-details.component';
import { DeviceConfigurationDialogComponent } from './device-configuration-dialog/device-configuration-dialog.component';
import { IspCircuitsService } from '../../_services/entities/isp-circuits.service';
@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss'],
})
export class OperationsComponent implements OnInit {
  dataSource = [];
  tabs: any[];
  activeTab = 0;
  ref: DynamicDialogRef | undefined;
  selectedRow: any;
  selectedRowCM: any;
  selectedRowCMObservable$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  message: { [key: string]: any } = {};
  basicData: any;
  horizontalOptions: any;
  configuredDevices: any;
  billedDevices: any;
  availableDevices: any;
  notifiedDevices: any;
  totalDevices: any;

  public formData: JsonFormData | undefined;
  public vnoFormData: JsonFormData | undefined;
  public regionFormData: JsonFormData | undefined;
  public ispFormData: JsonFormData | undefined;
  public productFormData: JsonFormData | undefined;

  schemaDefinitions: any;

  tabDataSources: { [index: string]: any };
  routeActiveTab$: any;
  devicesSummaryStatistics: any;

  constructor(
    private http: HttpClient,

    public dialogService: DialogService,
    private confirmationService: ConfirmationService,

    private route: ActivatedRoute,
    private router: Router,

    private tenantsService: TenantsService,

    public vnosService: VnosService,
    public regionsService: RegionsService,
    public ispsService: IspsService,
    public ispCircuitsService: IspCircuitsService,
    public vlansService: VlansService,
    public productsService: ProductsService,
    public contactsService: ContactsService,
    public sitesService: SitesService,
    public endCustomersService: EndCustomersService,
    public equipmentTypesService: EquipmentTypesService,
    public devicesService: DevicesService,
    public endCustomerCircuitsService: EndCustomerCircuitsService,
    public tocsService: TocsService,

    public entityService: EntityService,

    // testing related
    public relationshipsService: RelationshipService,

    public userPreferencesService: UserPreferencesService,

    public statisticsService: StatisticsService
  ) {
    // todo: derive this from the schema
    this.tabs = [
      { key: 'vno', name: 'VNOs' },
      { key: 'region', name: 'Regions' },
      { key: 'isp', name: 'ISPs' },
      { key: 'ispcircuit', name: 'ISP Circuits' },
      { key: 'vlan', name: 'VLANs' },
      { key: 'product', name: 'Products' },
      { key: 'equipmenttype', name: 'Equipment' },
      { key: 'site', name: 'Sites' },
      { key: 'contact', name: 'Contacts' },
      { key: 'device', name: 'Devices' },
      { key: 'endcustomer', name: 'End Customers' },
      { key: 'endcustomercircuit', name: 'End Customer Circuits' },
      { key: 'toc', name: 'TOCs' },
    ];

    this.tabDataSources = {
      vno: vnosService.vnos$,
      region: regionsService.regions$,
      //   region: relationshipsService.regionsRelated$,
      isp: ispsService.isps$,
      //   isp: relationshipsService.ispsRelated$,
      // vlan: vlansService.vlans$,
      ispcircuit: ispCircuitsService.ispCircuits$,
      vlan: vlansService.vlans$,
      // product: productsService.products$,
      product: productsService.products$,
      //   product: relationshipsService.productsRelated$,
      // equipmenttype: relationshipsService.equipmentTypesRelated$,
      equipmenttype: equipmentTypesService.equipmentTypes$,
      // site: sitesService.sites$,
      site: sitesService.sites$,
      // contact: contactsService.contacts$,
      contact: contactsService.contacts$,
      // endcustomer: endCustomersService.endCustomers$,
      //   endcustomer: relationshipsService.endCustomersRelated$,
      endcustomer: endCustomersService.endCustomers$,
      // device: devicesService.devices$,
      device: devicesService.devices$,
      // endcustomercircuit: endCustomerCircuitsService.endCustomerCircuits$,
      endcustomercircuit: endCustomerCircuitsService.endCustomerCircuits$,
      toc: tocsService.tocs$,
    };

    this.entityService.entityServices['Vlans'].service = vlansService;
    this.entityService.entityServices['Vnos'].service = vnosService;
    this.entityService.entityServices['Regions'].service = regionsService;
    this.entityService.entityServices['Isps'].service = ispsService;
    this.entityService.entityServices['IspCircuits'].service =
      ispCircuitsService;
    this.entityService.entityServices['Products'].service = productsService;
    this.entityService.entityServices['Contacts'].service = contactsService;
    this.entityService.entityServices['Sites'].service = sitesService;
    this.entityService.entityServices['Devices'].service = devicesService;
    this.entityService.entityServices['EquipmentTypes'].service =
      equipmentTypesService;
    this.entityService.entityServices['EndCustomers'].service =
      endCustomersService;
    this.entityService.entityServices['EndCustomerCircuits'].service =
      endCustomerCircuitsService;
    this.entityService.entityServices['Tocs'].service = tocsService;
  }

  ngOnInit(): void {
    this.statisticsService.getDeviceStatistics().subscribe((data: any) => {
      this.devicesSummaryStatistics = data;
    });

    // todo: implement stats for devices as an endpoint

    // this.tabDataSources['device'].subscribe((data: any) => {});

    this.route.queryParams.subscribe((params) => {
      console.log('query params', params);
    });

    this.routeActiveTab$ = this.route.params.subscribe((params) => {
      const activeRouteTab = params['activeTab'];

      if (activeRouteTab) {
        const activeTabIndex = this.tabs.findIndex(
          (tab) => tab.key === activeRouteTab
        );
        this.activeTab = activeTabIndex;
      }
    });

    this.userPreferencesService.getUserPreferences();

    // //console.log('home ng on init start', ngoninitStart.toISOString());
    this.http
      .get('/assets/schema-definitions.json')
      .subscribe((schemaData: any) => {
        this.schemaDefinitions = schemaData;
      });

    // 1. get all the entities
    // -----------------------

    // let tab = this.tabs[0];
    for (const tab of this.tabs) {
      // console.log('getting entities for tab', tab.key);
      this.entityService.getEntities(tab.key);
    }

    // 2. get all the relationships
    // ----------------------------

    // this.relationshipsService.getvnoregion();
    // this.relationshipsService.getvnoisp();
    // this.relationshipsService.getproductcontact();
    // this.relationshipsService.getproductequipmenttype();
    // this.relationshipsService.getregioncontact();
    // this.relationshipsService.getAll();
  }

  changeTab(tabevent: any) {
    // todo: get new data for tab when made active

    const currentActiveTab = this.tabs[tabevent.index];

    const tab: any = currentActiveTab;
    this.router.navigate(['operations', 'tab', tab.key]);
    this.getTabData(tab);
  }

  getTabData(tab: any) {
    this.entityService.getEntities(tab.key);
  }

  recieveMessage(selectedRowData: any, tab: any) {
    this.message[tab.key] = selectedRowData;
  }

  showAdd(tab: any) {
    this.ref = this.dialogService.open(FormComponent, {
      data: {
        formdata: null,
        schema: this.schemaDefinitions[tab.key],
        tab: tab,
      },
      header: 'Create a new entity in ' + tab.name,
      width: '70%',
      height: '70%',
      closeOnEscape: true,
    });
  }

  showEdit($event: any, tab: any) {
    //console.log('row', $event);
    const row = $event;
    this.ref = this.dialogService.open(FormComponent, {
      data: {
        formdata: row,
        schema: this.schemaDefinitions[tab.key],
        tab: tab,
      },
      header: 'Edit ' + tab.name,
      width: '70%',
      height: '70%',
      closeOnEscape: true,
    });
  }

  showView($event: any, tab: any) {
    //console.log('row', $event);
    const row = $event;
    this.ref = this.dialogService.open(ViewDetailsComponent, {
      data: {
        entityData: row,
        schema: this.schemaDefinitions[tab.key],
        tab: tab,
        mode: 'view',
      },
      header: 'View ' + tab.name,
      width: '70%',
      height: '90%',
      closeOnEscape: true,
    });
  }

  showDeleteAll(tab: any) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete all ' + tab.name + '?',
      accept: () => {
        //Actual logic to perform a confirmation
        //console.log('delete', $event);
        this.entityService.deleteAllEntities(tab.key);
      },
    });
  }

  getInputValue(e: any): any {
    const inputelement = e.target as HTMLInputElement;
    return inputelement.value;
  }

  onRowSelect($event: any) {
    //console.log($event);
  }

  onContextMenu($event: any) {
    this.selectedRowCM = $event.data;
    //console.log('cm', $event);
  }

  showContextAction($event: any, tab: any) {
    console.log('context action', $event);
    console.log('tab', tab);

    switch ($event.action) {
      case 'edit':
        this.showEdit($event.rowData, tab);
        break;
      case 'delete':
        this.showDelete($event.rowData, tab);
        break;
      case 'view':
        this.showView($event.rowData, tab);
        break;
      default:
        break;
    }
  }
  showDelete(data: any, tab: any) {
    this.confirmationService.confirm({
      message:
        'Are you sure that you want to delete the selected ' + tab.name + '?',
      accept: () => {
        //Actual logic to perform a confirmation
        //console.log('delete', $event);
        this.entityService.deleteEntity(tab.key, data.id);
      },
    });
  }

  showDetails(selectedRowData: any, tab: any) {
    console.log('details', selectedRowData);
    console.log('tab', tab);
    this.message[tab.key] = selectedRowData;
  }

  showRelatedEntity($event: any) {
    console.log('show related entity', $event);

    //path = equipment_type.model

    // entity = {}

    // get base entity
    const baseEntityTab = $event.path.split('.')[0].replace(/_/g, '');

    this.navigateToRelatedEntity(baseEntityTab, $event.data);
  }

  navigateToRelatedEntity(tab: any, data: any) {
    console.log('navigate to related entity', tab, data);
    this.router.navigate(['operations', 'tab', tab], {
      queryParams: { id: 1 },
    });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, of } from 'rxjs';
import { ApiService } from 'src/app/_core/_services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AlarmsService {
  constructor(private http: HttpClient, private api: ApiService) {}

  private cache: any = null;
  private cacheExpiration: number = 0;

  getAlarms() {
    const now = Date.now();
    if (this.cache && now < this.cacheExpiration) {
      return of(this.cache);
    }

    return this.api.getAPI('network-portal/operations/alarms').pipe(
      map((response: any) => {
        this.cache = response.body;
        // this.cacheExpiration = now + 3600000; // 1 hour in milliseconds
        this.cacheExpiration = now + 600000; // 10 minutes in milliseconds

        return this.cache;
      })
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-services-charges',
  templateUrl: './services-charges.component.html',
  styleUrls: ['./services-charges.component.scss'],
})
export class ServicesChargesComponent implements OnInit {
  quantities1: number[] = [1, 1, 1];

  quantities2: number[] = [1, 1];

  quantityOptions: SelectItem[] = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
  ];

  visibleDialog: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}

import { Component, OnInit } from '@angular/core';
import { SitesService } from 'src/app/features/main-application/_services/entities/sites.service';
declare const google: any;
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  constructor(private ss: SitesService) {}

  selectedArea: any = {
    name: 'Windhoek',
    key: 'windhoek',
    lat: -22.5609,
    lng: 17.0658,
  };

  areas = [
    { name: 'Windhoek', key: 'windhoek', lat: -22.5609, lng: 17.0658 },
    { name: 'Swakopmund', key: 'swakopmund', lat: -22.6787, lng: 14.535 },
    { name: 'Walvis Bay', key: 'walvis-bay', lat: -22.957019, lng: 14.50561 },
  ];

  mapOptions: any = {};

  overlays: any[] = [];

  orders = [
    {
      id: 'ORD001',
      status: 'Pending',
      dateCreated: new Date('2024-09-15'),
      dateModified: new Date('2024-09-16'),
    },
    {
      id: 'ORD002',
      status: 'Completed',
      dateCreated: new Date('2024-09-14'),
      dateModified: new Date('2024-09-15'),
    },
    // Add more mock data as needed
  ];

  ngOnInit(): void {
    this.mapOptions = {
      center: { lat: -22.957019, lng: 14.50561 },
      zoom: 12,
    };

    this.ss.getSites().subscribe((sites) => {
      console.log('sites', sites);
      sites.data.map((site: any) => {
        this.overlays.push(
          new google.maps.Marker({
            position: {
              lat: site.latitude,
              lng: site.longitude,
            },
            // label: site.name,
          })
        );
      });
    });
    this.overlays.push(
      new google.maps.KmlLayer(
        'https://www.demshiinvest.com/uploads/files/Demshi_Network_Coverage_2.kmz',
        {
          suppressInfoWindows: true,
          preserveViewport: true,
        }
      )
    );
  }

  onAreaChange(event: any, map: any) {
    console.log('map event', event);
    console.log('map selected', map);

    map.setCenter({
      lat: event.value.lat,
      lng: event.value.lng,
    });
  }
}

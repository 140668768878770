import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/_core/_services/api.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(private api: ApiService) {}

  updateDeviceConfiguration(value: any) {
    return this.api.patchAPI<any>(
      `network-portal/operations/configuration`,
      value
    );
  }
}

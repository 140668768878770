import { Component, OnInit } from '@angular/core';

interface Conversation {
  id: number;
  name: string;
  reciepients: string[];
  lastMessage: string;
  messages: Message[];
}

interface Message {
  id: number;
  sender: string;
  content: string;
  timestamp: string;
  status?: string;
}

@Component({
  selector: 'app-conversation-view',
  templateUrl: './conversation-view.component.html',
  styleUrls: ['./conversation-view.component.scss'],
})
export class ConversationViewComponent implements OnInit {
  startConversation() {
    throw new Error('Method not implemented.');
  }
  createNewConversation() {
    throw new Error('Method not implemented.');
  }
  selectedConversation: any;
  newMessage: any;

  conversationVisible: boolean = false;
  conversations: Conversation[] = [
    {
      id: 1,
      name: 'Jimmy McGowran',
      reciepients: ['Jimmy McGowran'],
      lastMessage: '',
      messages: [
        {
          id: 1,
          sender: 'Jimmy McGowran',
          content: 'Thanks for getting in touch. How can I help you today?',

          timestamp: '10:32 AM',
          status: 'delivered',
        },
        {
          id: 2,
          sender: 'You',
          content:
            'A new sales call was logged. Ref: 12345. Customer: John Doe.',

          timestamp: '10:32 AM',
          status: 'delivered',
        },
        {
          id: 2,
          sender: 'You',
          content:
            'A new support call was logged. Ref: 12345. Customer: Jane Doe. SupportPal ticket no: 12345',
          timestamp: '10:47 AM',
          status: 'delivered',
        },
        {
          id: 3,
          sender: 'You',
          content:
            'A new sales call was logged. Ref: 12346. Customer: Alice Smith.',
          timestamp: '11:15 AM',
          status: 'delivered',
        },
        {
          id: 4,
          sender: 'You',
          content:
            'A new support call was logged. Ref: 12347. Customer: Bob Johnson. SupportPal ticket no: 12346',
          timestamp: '11:30 AM',
          status: 'delivered',
        },
        {
          id: 5,
          sender: 'You',
          content:
            'A new sales call was logged. Ref: 12348. Customer: Charlie Brown.',
          timestamp: '11:45 AM',
          status: 'delivered',
        },
      ],
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.selectedConversation = this.conversations[0];
  }

  selectConversation(conversation: Conversation) {
    // change the selected conversation
    this.selectedConversation = conversation;
  }

  sendMessage() {
    // if new message is empty, return
    if (!this.newMessage) {
      return false;
    }
    // add the new message to the selected conversation
    this.selectedConversation.messages.push({
      id: this.selectedConversation.messages.length + 1,
      sender: 'You',
      content: this.newMessage,
      timestamp: new Date()

        .toLocaleTimeString
        // format the timestamp to show only the time
        (),
      status: 'delivered',
    });

    // clear the new message input
    this.newMessage = '';
    return true;
  }
}
